<template>
  <c-box>
    <c-box
      position="sticky"
      top="calc(84px)"
      background-color="white"
      z-index="10"
      box-shadow="0px 16px 0px white, 10px 16px 0px white, -10px 16px 0px white, 10px -16px 0px white, -10px -16px 0px white"
    >
      <c-flex
        width="100%"
        align-items="center"
        justify-content="space-between"
        margin-bottom="16px"
      >
        <c-button
          variant="ghost"
          width="30px"
          min-width="30px"
          height="30px"
          margin="0"
          padding="0"
          margin-right="16px"
          @click="onClickBackToPreview"
        >
          <c-box
            :width="['12px', '15px']"
            :height="['12px', '15px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
              style="transform: rotate(90deg);"
            />
          </c-box>
        </c-button>
        <c-text
          color="neutral.superDarkGray"
          font-size="22px"
          font-weight="600"
          margin-right="auto"
        >
          Kondisi Giziku
        </c-text>
        <c-tooltip
          :label="iconCloud.text"
          placement="bottom"
        >
          <c-box
            :width="['35px']"
            :height="['35px']"
            margin-right="16px"
            :class="iconCloud.state === 'loading' ? 'animate-spin' : ''"
          >
            <inline-svg
              :src="iconCloud.icon"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
        </c-tooltip>
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-save.svg')"
          left-svg-icon-color="white"
          :disabled="!isValidAllFormProfilGizi || saveState === null || isLoadingSave"
          @click="onSave"
        >
          Simpan
        </BaseButton>
      </c-flex>

      <NutritionalProfileSteps
        :draft="draft"
        :nutritional-conditions="!isDisabledSubmit ? 1 : -1"
        :is-loading="isLoadingSave || saveState === null"
        @change:step="(step) => handleChangeStep(step, true)"
      />
    </c-box>

    <c-box padding-top="16px">
      <FormAccordionList 
        :allow-toggle="false"
        gap="8px"
      >
        <FormAccordionListItem
          label="Data Diri"
          :is-error="!isSectionDataDiriValid"
          with-border-header
          default-is-open
        >
          <c-grid
            template-columns="repeat(2, minmax(0, 1fr))"
            gap="12px"
          >
            <FormInput
              v-model="form.name"
              type="text"
              label="Nama Lengkap"
              placeholder="Masukkan nama lengkap"
              is-required
              is-disabled
              :is-invalid="$v.form.name.$invalid"
            />

            <FormSelect
              v-model="form.gender"
              label="Jenis Kelamin"
              placeholder="Pilih Jenis Kelamin"
              is-required
              is-disabled
              :options="[
                {
                  value: 'male',
                  label: 'Laki-laki',
                },
                {
                  value: 'female',
                  label: 'Perempuan',
                }
              ]"
              :is-invalid="$v.form.gender.$invalid"
            />
          </c-grid>
          
          <c-grid
            template-columns="repeat(3, minmax(0, 1fr))"
            gap="12px"
          >
            <FormInput
              :value="form.age"
              type="number"
              label="Umur (tahun)"
              placeholder="Masukkan umur"
              is-required
              is-disabled
              right-element="Tahun"
              custom-padding-right-input="85px"
              is-without-margin-bottom
              :is-invalid="$v.form.age.$invalid"
            />

            <FormInput
              v-model="form.weight"
              type="text"
              label="Berat Badan (kg)"
              placeholder="Masukkan berat badan (kg)"
              is-required
              :is-disabled="!isEditWeight"
              :is-invalid="isInvalidField($v.form.weight)"
              :invalid-text="parseErrors('Berat Badan (kg)', $v.form.weight, customMessageErrors)"
              :icon-right-element="!isEditWeight ? require('@/assets/icons/icon-edit-2.svg') : null"
              icon-right-element-color="#008C81"
              right-element="kg"
              custom-padding-right-input="55px"
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="`Berat badan dari formulir gizi: ${basicData?.weight} kg`"
              is-without-margin-bottom
              @right-element="onEditWeight"
              @blur="$v.form.weight.$touch"
            />

            <FormInput
              v-model="form.height"
              type="text"
              label="Tinggi Badan (cm)"
              placeholder="Masukkan tinggi badan (cm)"
              is-required
              :is-disabled="!isEditHeight"
              :is-invalid="isInvalidField($v.form.height)"
              :invalid-text="parseErrors('Tinggi Badan (cm)', $v.form.height, customMessageErrors)"
              :icon-right-element="!isEditHeight ? require('@/assets/icons/icon-edit-2.svg') : null"
              icon-right-element-color="#008C81"
              right-element="cm"
              custom-padding-right-input="60px"
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="`Tinggi badan dari formulir gizi: ${basicData?.height} cm`"
              is-without-margin-bottom
              @right-element="onEditHeight"
              @blur="$v.form.height.$touch"
            />
          </c-grid>
        </FormAccordionListItem>
        <c-divider />
        <FormRadio
          v-model="form.calculationType"
          label="Jenis Perhitungan Gizi & Kebutuhan Energi"
          is-required
          :options="constants.calculationTypes"
          :is-invalid="isInvalidField($v.form.calculationType)"
          :invalid-text="parseErrors('Jenis Perhitungan', $v.form.calculationType, customMessageErrors)"
          is-without-margin-bottom
          is-inline
          spacing="8"
        />
        <c-divider />

        <FormAccordionListItem
          v-if="form.calculationType === 'child'"
          label="Status Gizi Anak"
          :is-error="!isSectionStatusGiziAnakValid"
          default-is-open
        >
          <c-flex
            gap="16px"
            width="100%"
          >
            <FormInput
              v-model="form.correctedHeight"
              type="number"
              label="Koreksi Tinggi Badan atau Panjang Badan (cm)"
              placeholder="Masukkan Koreksi Tinggi Badan atau Panjang Badan (cm)"
              is-required
              right-element="cm"
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              helper-text="Isi sesuai data dasar jika tidak ada koreksi"
              :is-invalid="isInvalidField($v.form.correctedHeight)"
              :invalid-text="parseErrors('Koreksi Tinggi Badan atau Panjang Badan (cm)', $v.form.correctedHeight, customMessageErrors)"
              @blur="$v.form.correctedHeight.$touch"
            />

            <FormInput
              v-model="form.imt"
              type="number"
              label="Indeks Massa Tubuh (IMT) (kg/m2)"
              placeholder="Masukkan Indeks Massa Tubuh (IMT) (kg/m2)"
              is-required
              is-disabled
              right-element="kg/m2"
              :is-invalid="$v.form.imt.$invalid"
            />
          </c-flex>

          <FormRadio
            v-model="form.childAgeRange"
            label="Rentang Usia Anak"
            placeholder="Pilih Rentang Usia Anak"
            is-required
            :options="constants.childAgeRanges"
            :is-invalid="isInvalidField($v.form.childAgeRange)"
            :invalid-text="parseErrors('Rentang Usia Anak', $v.form.childAgeRange, customMessageErrors)"
            is-without-margin-bottom
            is-inline
            @blur="$v.form.childAgeRange.$touch"
          />
        </FormAccordionListItem>

        <FormAccordionListItem
          v-if="form.calculationType === 'child' && form.childAgeRange === '0 - 60 bulan'"
          label="Status Gizi Anak 0 - 60 Bulan"
          :is-error="!isSectionStatusGiziAnak060BulanValid"
          default-is-open
        >
          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeMonthNutritionalStatusWeightPerAgeOptions"
              v-model="form.childAgeRangeMonthNutritionalStatusWeightPerAge"
              label="Z-Score Berat Badan/Umur (BB/U)"
              placeholder="Pilih Z-Score Berat Badan/Umur (BB/U)"
              is-required
              is-inline
              :options="childAgeRangeMonthNutritionalStatusWeightPerAgeOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeMonthNutritionalStatusWeightPerAge)"
              :invalid-text="parseErrors('Z-Score Berat Badan/Umur (BB/U)', $v.form.childAgeRangeMonthNutritionalStatusWeightPerAge, customMessageErrors)"
              @blur="$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge.$touch"
            />
            <StatusLabel
              label="Status Gizi"
              :value="form.childAgeRangeMonthNutritionalStatusWeightPerAge || '-'"
              :color="childAgeRangeMonthNutritionalStatusWeightPerAgeOptions?.find((v) => v.value === form.childAgeRangeMonthNutritionalStatusWeightPerAge)?.color"
            />
          </c-flex>

          <c-divider />

          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeMonthNutritionalStatusHeightPerAgeOptions"
              v-model="form.childAgeRangeMonthNutritionalStatusHeightPerAge"
              label="Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)"
              placeholder="Pilih Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)"
              is-required
              is-inline
              :options="childAgeRangeMonthNutritionalStatusHeightPerAgeOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeMonthNutritionalStatusHeightPerAge)"
              :invalid-text="parseErrors('Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)', $v.form.childAgeRangeMonthNutritionalStatusHeightPerAge, customMessageErrors)"
              @blur="$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge.$touch"
            />
            <StatusLabel
              label="Status Gizi"
              :value="form.childAgeRangeMonthNutritionalStatusHeightPerAge || '-'"
              :color="childAgeRangeMonthNutritionalStatusHeightPerAgeOptions?.find((v) => v.value === form.childAgeRangeMonthNutritionalStatusHeightPerAge)?.color"
            />
          </c-flex>

          <c-divider />

          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeMonthNutritionalStatusHeightPerWeightOptions"
              v-model="form.childAgeRangeMonthNutritionalStatusHeightPerWeight"
              label="Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)"
              placeholder="Pilih Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)"
              is-required
              is-inline
              :options="childAgeRangeMonthNutritionalStatusHeightPerWeightOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight)"
              :invalid-text="parseErrors('Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)', $v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight, customMessageErrors)"
              @blur="$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight.$touch"
            />
            <StatusLabel
              label="Status Gizi"
              :value="form.childAgeRangeMonthNutritionalStatusHeightPerWeight || '-'"
              :color="childAgeRangeMonthNutritionalStatusHeightPerWeightOptions?.find((v) => v.value === form.childAgeRangeMonthNutritionalStatusHeightPerWeight)?.color"
            />
          </c-flex>

          <c-divider />

          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeMonthNutritionalStatusImtPerAgeOptions"
              v-model="form.childAgeRangeMonthNutritionalStatusImtPerAge"
              label="Z-Score Indeks Massa Tubuh/Umur (IMT/U)"
              placeholder="Pilih Z-Score Indeks Massa Tubuh/Umur (IMT/U)"
              is-required
              is-inline
              :options="childAgeRangeMonthNutritionalStatusImtPerAgeOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeMonthNutritionalStatusImtPerAge)"
              :invalid-text="parseErrors('Z-Score Indeks Massa Tubuh/Umur (IMT/U)', $v.form.childAgeRangeMonthNutritionalStatusImtPerAge, customMessageErrors)"
              @blur="$v.form.childAgeRangeMonthNutritionalStatusImtPerAge.$touch"
            />
            <StatusLabel
              label="Status Gizi"
              :value="form.childAgeRangeMonthNutritionalStatusImtPerAge || '-'"
              :color="childAgeRangeMonthNutritionalStatusImtPerAgeOptions?.find((v) => v.value === form.childAgeRangeMonthNutritionalStatusImtPerAge)?.color"
            />
          </c-flex>

          <c-divider />

          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeMonthNutritionalStatusConclusionOptions"
              v-model="form.childAgeRangeMonthNutritionalStatusConclusionChoice"
              label="Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan"
              placeholder="Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan"
              is-required
              is-inline
              :options="constants.conclusionOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeMonthNutritionalStatusConclusionChoice)"
              :invalid-text="parseErrors('Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan', $v.form.childAgeRangeMonthNutritionalStatusConclusionChoice, customMessageErrors)"
              @blur="$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice.$touch"
            />
            <StatusLabel
              label="Kesimpulan Status Gizi Anak 0 - 60 Bulan"
              :value="form.childAgeRangeMonthNutritionalStatusConclusion || '-'"
              :color="childAgeRangeMonthNutritionalStatusImtPerAgeOptions?.find((v) => v.value === form.childAgeRangeMonthNutritionalStatusConclusion)?.color"
            />
          </c-flex>
        </FormAccordionListItem>

        <FormAccordionListItem
          v-if="form.calculationType === 'child' && form.childAgeRange === '> 5 - 18 tahun'"
          label="Status Gizi Anak > 5 - 18 Tahun"
          :is-error="!isSectionStatusGiziAnak518TahunValid"
          default-is-open
        >
          <c-flex
            gap="16px"
            justify-content="space-between"
          >
            <FormRadio
              key="childAgeRangeYearNutritionalStatusImtPerAgeOptions"
              v-model="form.childAgeRangeYearNutritionalStatusImtPerAge"
              label="Z-Score Indeks Massa Tubuh/Umur (IMT/U)"
              placeholder="Pilih Z-Score Indeks Massa Tubuh/Umur (IMT/U)"
              is-required
              is-inline
              :options="childAgeRangeYearNutritionalStatusImtPerAgeOptions"
              :is-invalid="isInvalidField($v.form.childAgeRangeYearNutritionalStatusImtPerAge)"
              :invalid-text="parseErrors('Z-Score Indeks Massa Tubuh/Umur (IMT/U)', $v.form.childAgeRangeYearNutritionalStatusImtPerAge, customMessageErrors)"
              @blur="$v.form.childAgeRangeYearNutritionalStatusImtPerAge.$touch"
            />

            <StatusLabel
              label="Status Gizi"
              :value="form.childAgeRangeYearNutritionalStatusImtPerAge || '-'"
              :color="childAgeRangeYearNutritionalStatusImtPerAgeOptions?.find((v) => v.value === form.childAgeRangeYearNutritionalStatusImtPerAge)?.color"
            />
          </c-flex>
        </FormAccordionListItem>

        <FormAccordionListItem
          v-if="(form.calculationType === 'child'
            && form.childAgeRangeMonthNutritionalStatusWeightPerAge
            && form.childAgeRangeMonthNutritionalStatusHeightPerAge
            && form.childAgeRangeMonthNutritionalStatusHeightPerWeight
            && form.childAgeRangeMonthNutritionalStatusImtPerAge
            && form.childAgeRangeMonthNutritionalStatusConclusionChoice)
            || (form.calculationType === 'child'
              && form.childAgeRangeYearNutritionalStatusImtPerAge)
          "
          label="Kebutuhan Energi Anak"
          :is-error="!isSectionKebutuhanEnergiAnakValid"
          default-is-open
        >
          <c-flex
            gap="16px"
            width="100%"
          >
            <FormInput
              v-model="form.childIdealWeight"
              type="number"
              label="Berat Badan Ideal Anak (kg)"
              placeholder="Masukkan Berat Badan Ideal Anak (kg)"
              is-required
              right-element="kg"
              is-without-margin-bottom
              :is-invalid="isInvalidField($v.form.childIdealWeight)"
              :invalid-text="parseErrors('Berat Badan Ideal Anak (kg)', $v.form.childIdealWeight, customMessageErrors)"
              @blur="$v.form.childIdealWeight.$touch"
            />

            <FormInput
              v-model="form.childIdealHeight"
              type="number"
              label="Tinggi atau Panjang Badan Ideal Anak (cm)"
              placeholder="Masukkan Tinggi atau Panjang Badan Ideal Anak (cm)"
              is-required
              right-element="cm"
              is-without-margin-bottom
              custom-padding-right-input="70px"
              :is-invalid="isInvalidField($v.form.childIdealHeight)"
              :invalid-text="parseErrors('Tinggi atau Panjang Badan Ideal Anak (cm)', $v.form.childIdealHeight, customMessageErrors)"
              @blur="$v.form.childIdealHeight.$touch"
            />

            <FormInput
              v-model="form.ageAccordingHeight"
              type="text"
              label="Usia sesuai Tinggi Badan atau Panjang Badan"
              placeholder="Masukkan Usia sesuai Tinggi Badan atau Panjang Badan"
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              is-without-margin-bottom
              helper-text="Masukkan usia sesuai dengan satuan bulan atau tahun. Contoh isian: 11 bulan atau 5 tahun"
              :is-invalid="isInvalidField($v.form.ageAccordingHeight)"
              :invalid-text="parseErrors('Usia sesuai Tinggi Badan atau Panjang Badan', $v.form.ageAccordingHeight, customMessageErrors)"
              @blur="$v.form.ageAccordingHeight.$touch"
            />
          </c-flex>

          <FormRadio
            v-model="form.formula"
            label="Jenis Perhitungan Energi Sesuai Usia Tinggi"
            placeholder="Pilih Jenis Perhitungan Energi Sesuai Usia Tinggi"
            is-required
            is-inline
            :is-disabled="!form.childAgeRange"
            :options="form.childAgeRange === '0 - 60 bulan' ? constants.childFormulas : constants.childFormulas2"
            :is-invalid="isInvalidField($v.form.formula)"
            :invalid-text="!form.childAgeRange ? 'Pilih Rentang Usia Anak dahulu' : parseErrors('Jenis Perhitungan Energi Sesuai Usia Tinggi', $v.form.formula, customMessageErrors)"
            :is-without-margin-bottom="form.formula !== 'manualFormula'"
            @blur="$v.form.formula.$touch"
          />

          <c-divider />

          <FormInput
            v-if="form.formula === 'manualFormula'"
            v-model="form.manualFormulaTotalEnergyNeeded"
            type="number"
            label="Total Kebutuhan Energi (kkal)"
            placeholder="Masukkan Total Kebutuhan Energi (kkal)"
            is-required
            right-element="kkal"
            :is-invalid="isInvalidField($v.form.manualFormulaTotalEnergyNeeded)"
            :invalid-text="parseErrors('Total Kebutuhan Energi (kkal)', $v.form.manualFormulaTotalEnergyNeeded, customMessageErrors)"
            is-without-margin-bottom
            @blur="$v.form.manualFormulaTotalEnergyNeeded.$touch"
          />

          <c-flex
            v-if="form.calculationType === 'child' && form.formula === 'monthFormula'"
            align-items="center"
            justify-content="space-between"
            gap="16px"
          >
            <FormRadio
              v-model="form.monthFormulaCalculationAccordingAgeAndHeight"
              label="Opsi Perhitungan Sesuai Usia Tinggi"
              placeholder="Pilih Opsi Perhitungan Sesuai Usia Tinggi"
              is-required
              is-inline
              is-without-margin-bottom
              :options="constants.monthFormulas"
              :is-invalid="isInvalidField($v.form.monthFormulaCalculationAccordingAgeAndHeight)"
              :invalid-text="parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', $v.form.monthFormulaCalculationAccordingAgeAndHeight, customMessageErrors)"
              @blur="$v.form.monthFormulaCalculationAccordingAgeAndHeight.$touch"
            />

            <c-box
              width="100%"
              max-width="430px"
            >
              <FormInput
                v-model="form.monthFormulaTotalEnergyNeeded"
                type="number"
                label="Total Kebutuhan Energi (kkal)"
                placeholder="Masukkan Total Kebutuhan Energi (kkal)"
                is-required
                is-disabled
                right-element="kkal"
                :is-invalid="isInvalidField($v.form.monthFormulaTotalEnergyNeeded)"
                invalid-text="Selesaikan isian semua data terlebih dahulu"
                is-without-margin-bottom
              />
            </c-box>
          </c-flex>

          <c-flex
            v-if="form.calculationType === 'child' && form.formula === 'yearMaleFormula'"
            gap="20px"
          >
            <FormRadio
              v-model="form.yearMaleFormulaCalculationAccordingAgeAndHeight"
              label="Opsi Perhitungan Sesuai Usia Tinggi"
              placeholder="Pilih Opsi Perhitungan Sesuai Usia Tinggi"
              is-required
              is-inline
              is-without-margin-bottom
              :options="constants.yearMaleFormulas"
              :is-invalid="isInvalidField($v.form.yearMaleFormulaCalculationAccordingAgeAndHeight)"
              :invalid-text="parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', $v.form.yearMaleFormulaCalculationAccordingAgeAndHeight, customMessageErrors)"
              @blur="$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight.$touch"
            />
            <FormInput
              v-model="form.yearFormulaTotalEnergyNeeded"
              type="number"
              label="Total Kebutuhan Energi (kkal)"
              placeholder="Masukkan Total Kebutuhan Energi (kkal)"
              is-required
              is-disabled
              custom-padding-right-input="85px"
              right-element="kkal"
              :is-invalid="isInvalidField($v.form.yearFormulaTotalEnergyNeeded)"
              invalid-text="Selesaikan isian semua data terlebih dahulu"
              is-without-margin-bottom
            />
          </c-flex>

          <c-flex
            v-if="form.calculationType === 'child' && form.formula === 'yearFemaleFormula'"
            gap="20px"
          >
            <FormRadio
              v-model="form.yearFemaleFormulaCalculationAccordingAgeAndHeight"
              label="Opsi Perhitungan Sesuai Usia Tinggi"
              placeholder="Pilih Opsi Perhitungan Sesuai Usia Tinggi"
              is-required
              is-inline
              :options="constants.yearFemaleFormulas"
              :is-invalid="isInvalidField($v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight)"
              :invalid-text="parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', $v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight, customMessageErrors)"
              @blur="$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight.$touch"
            />

            <FormInput
              v-model="form.yearFormulaTotalEnergyNeeded"
              type="number"
              label="Total Kebutuhan Energi (kkal)"
              placeholder="Masukkan Total Kebutuhan Energi (kkal)"
              is-required
              is-disabled
              custom-padding-right-input="85px"
              right-element="kkal"
              :is-invalid="isInvalidField($v.form.yearFormulaTotalEnergyNeeded)"
              invalid-text="Selesaikan isian semua data terlebih dahulu"
              is-without-margin-bottom
            />
          </c-flex>
        </FormAccordionListItem>

        <FormAccordionListItem
          v-if="form.calculationType === 'adult'"
          label="Status Gizi Dewasa"
          :is-error="!isSectionStatusGiziDewasaValid"
          with-border-header
          default-is-open
        >
          <c-grid
            template-columns="repeat(2, minmax(0, 1fr))"
            gap="12px"
          >
            <FormInput
              v-model="form.imt"
              label="Indeks Massa Tubuh (IMT) (kg/m2)"
              placeholder="Masukkan Indeks Massa Tubuh (IMT) (kg/m2)"
              is-required
              is-disabled
              right-element="kg/m2"
              custom-padding-right-input="88px"
              :is-invalid="$v.form.imt.$invalid"
            />
            <FormInput
              v-model="form.nutritionalStatus"
              type="text"
              label="Status Gizi"
              placeholder="Masukkan Status Gizi"
              is-required
              is-disabled
              :is-invalid="$v.form.nutritionalStatus.$invalid"
            />
          </c-grid>
          <c-grid
            template-columns="repeat(5, minmax(0, 1fr))"
            gap="12px"
          >
            <FormInput
              v-model="form.minIdealWeight"
              label="Minimum Berat Badan Ideal (kg)"
              placeholder="Masukkan Minimum Berat Badan Ideal (kg)"
              is-required
              is-disabled
              right-element="kg"
              custom-padding-right-input="55px"
              :is-invalid="$v.form.minIdealWeight.$invalid"
              is-without-margin-bottom
            />
            <FormInput
              v-model="form.maxIdealWeight"
              label="Maximum Berat Badan Ideal (kg)"
              placeholder="Masukkan Maximum Berat Badan Ideal (kg)"
              is-required
              is-disabled
              right-element="kg"
              custom-padding-right-input="55px"
              :is-invalid="$v.form.maxIdealWeight.$invalid"
              is-without-margin-bottom
            />
            <FormInput
              v-model="form.medianIdealWeight"
              label="Nilai Tengah Berat Badan Ideal (kg)"
              placeholder="Masukkan Nilai Tengah Berat Badan Ideal (kg)"
              is-required
              is-disabled
              right-element="kg"
              custom-padding-right-input="55px"
              :is-invalid="$v.form.medianIdealWeight.$invalid"
              is-without-margin-bottom
            />
            <FormInput
              v-model="form.broccaIdealWeight"
              label="Berat Badan Ideal Brocca (kg)"
              placeholder="Masukkan Berat Badan Ideal Brocca (kg)"
              is-required
              is-disabled
              right-element="kg"
              custom-padding-right-input="55px"
              :is-invalid="$v.form.broccaIdealWeight.$invalid"
              is-without-margin-bottom
            />
            <FormInput
              v-model="form.adjustedIdealWeight"
              label="Berat Badan Ideal Adjusted (kg)"
              placeholder="Masukkan Berat Badan Ideal Adjusted (kg)"
              is-required
              is-disabled
              right-element="kg"
              custom-padding-right-input="55px"
              :is-invalid="$v.form.adjustedIdealWeight.$invalid"
              is-without-margin-bottom
            />
          </c-grid>
        </FormAccordionListItem>
        <c-divider v-if="form.calculationType === 'adult'" />
        <FormRadio
          v-if="form.calculationType === 'adult'"
          v-model="form.formula"
          label="Rumus Kebutuhan Energi Dewasa"
          is-required
          :options="constants.adultFormulas"
          :is-invalid="isInvalidField($v.form.formula)"
          :invalid-text="parseErrors('Rumus Kebutuhan Energi Dewasa', $v.form.formula, customMessageErrors)"
          :is-without-margin-bottom="form.formula !== 'manualFormula'"
          is-inline
          spacing="8"
        />
        <FormInput
          v-if="form.calculationType === 'adult' && form.formula === 'manualFormula'"
          v-model="form.manualFormulaTotalEnergyNeeded"
          type="number"
          label="Total Kebutuhan Energi (kkal)"
          placeholder="Masukkan Total Kebutuhan Energi (kkal)"
          is-required
          right-element="kkal"
          :is-invalid="isInvalidField($v.form.manualFormulaTotalEnergyNeeded)"
          :invalid-text="parseErrors('Total Kebutuhan Energi (kkal)', $v.form.manualFormulaTotalEnergyNeeded, customMessageErrors)"
          is-without-margin-bottom
          @blur="$v.form.manualFormulaTotalEnergyNeeded.$touch"
        />
        <c-divider v-if="form.calculationType === 'adult'" />
        <c-box
          v-if="form.calculationType === 'adult' && form.formula === 'mifflinStJeorFormula'"
        >
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.mifflinStJeorFormulaWeightChoice"
                label="Pilihan Berat Badan"
                is-required
                :options="constants.weightOptions"
                :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaWeightChoice)"
                :invalid-text="parseErrors('Pilihan Berat Badan', $v.form.mifflinStJeorFormulaWeightChoice, customMessageErrors)"
                is-inline
                spacing="8"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.mifflinStJeorFormulaBassEnergyRequirement"
                type="number"
                label="Kebutuhan Energi Bassal (kkal)"
                placeholder="Kebutuhan Energi Bassal (kkal)"
                is-required
                is-disabled
                right-element="kkal"
                custom-padding-right-input="70px"
                :is-invalid="$v.form.mifflinStJeorFormulaBassEnergyRequirement.$dirty"
              />
            </c-box>
          </c-flex>
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.mifflinStJeorFormulaPhysicalActivity"
                label="Pilih Aktivitas Fisik"
                is-required
                :options="constants.physicalActivityOptions"
                :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaPhysicalActivity)"
                :invalid-text="parseErrors('Aktivitas Fisik', $v.form.mifflinStJeorFormulaPhysicalActivity, customMessageErrors)"
                is-inline
                spacing="8"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.mifflinStJeorFormulaPhysicalActivity"
                type="text"
                label="% Aktivitas Fisik"
                placeholder="% Aktivitas Fisik"
                is-required
                is-disabled
                right-element="%"
                custom-padding-right-input="50px"
                :is-invalid="$v.form.mifflinStJeorFormulaPhysicalActivity.$invalid"
              />
            </c-box>
          </c-flex>
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.mifflinStJeorFormulaMaternalCondition"
                label="Kondisi Keibuan (kkal)"
                is-required
                :options="constants.maternalConditionOptions"
                :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaMaternalCondition)"
                :invalid-text="parseErrors('Kondisi Keibuan (kkal)', $v.form.mifflinStJeorFormulaMaternalCondition, customMessageErrors)"
                is-inline
                spacing="6"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.mifflinStJeorFormulaMaternalCondition"
                type="number"
                label="Tambahan Kalori Kondisi Keibuan (kkal)"
                placeholder="Tambahan Kalori Kondisi Keibuan (kkal)"
                is-required
                is-disabled
                right-element="kkal"
                custom-padding-right-input="70px"
                :is-invalid="$v.form.mifflinStJeorFormulaMaternalCondition.$invalid"
              />
            </c-box>
          </c-flex>
          <FormRadio
            v-model="form.mifflinStJeorFormulaCalorieChoice"
            label="Kalori yang diberikan (kkal)"
            is-required
            :options="constants.caloriesOptions"
            :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaCalorieChoice)"
            :invalid-text="parseErrors('Kalori yang diberikan (kkal)', $v.form.mifflinStJeorFormulaCalorieChoice, customMessageErrors)"
            is-inline
            spacing="8"
          />
          <c-grid
            template-columns="repeat(2, minmax(0, 1fr))"
            gap="16px"
          >
            <FormInput
              v-model="form.mifflinStJeorFormulaCalorieDeficit"
              type="number"
              label="Jumlah kalori yang diberikan"
              :placeholder="!form.mifflinStJeorFormulaCalorieChoice ? 'Pilih Kalori yang diberikan dahulu' : `Masukkan Jumlah ${constants.caloriesOptions.find((el) => el.value === form.mifflinStJeorFormulaCalorieChoice)?.label} (kkal)`"
              is-required
              :is-disabled="!form.mifflinStJeorFormulaCalorieChoice"
              right-element="kkal"
              custom-padding-right-input="70px"
              :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaCalorieDeficit)"
              :invalid-text="parseErrors(!form.mifflinStJeorFormulaCalorieChoice ? 'Pilih Kalori yang diberikan' : `Jumlah ${constants.caloriesOptions.find((el) => el.value === form.mifflinStJeorFormulaCalorieChoice)?.label} (kkal)`, $v.form.mifflinStJeorFormulaCalorieDeficit, customMessageErrors)"
              is-without-margin-bottom
              @blur="$v.form.mifflinStJeorFormulaCalorieDeficit.$touch"
            />
            <FormInput
              v-model="form.mifflinStJeorFormulaTotalEnergyNeeded"
              type="number"
              label="Total Kebutuhan Energi (kkal)"
              placeholder="Total Kebutuhan Energi (kkal)"
              is-required
              is-disabled
              right-element="kkal"
              custom-padding-right-input="70px"
              :is-invalid="isInvalidField($v.form.mifflinStJeorFormulaTotalEnergyNeeded)"
              invalid-text="Selesaikan isian semua data terlebih dahulu"
              is-without-margin-bottom
            />
          </c-grid>
        </c-box>
        <c-divider v-if="form.calculationType === 'adult' && form.formula === 'mifflinStJeorFormula'" />
        <c-box
          v-if="form.calculationType === 'adult' && form.formula === 'perkeniFormula'"
        >
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.perkeniFormulaWeightChoice"
                label="Pilihan Berat Badan"
                is-required
                :options="constants.weightOptions"
                :is-invalid="isInvalidField($v.form.perkeniFormulaWeightChoice)"
                :invalid-text="parseErrors('Pilihan Berat Badan', $v.form.perkeniFormulaWeightChoice, customMessageErrors)"
                is-inline
                spacing="8"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.perkeniFormulaBassEnergyRequirement"
                type="number"
                label="Kebutuhan Energi Bassal (kkal)"
                placeholder="Kebutuhan Energi Bassal (kkal)"
                is-required
                is-disabled
                right-element="kkal"
                custom-padding-right-input="70px"
                :is-invalid="$v.form.perkeniFormulaBassEnergyRequirement.$invalid"
              />
            </c-box>
          </c-flex>
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.perkeniFormulaPhysicalActivity"
                label="Pilih Aktivitas"
                is-required
                :options="constants.physicalActivityOptions"
                :is-invalid="isInvalidField($v.form.perkeniFormulaPhysicalActivity)"
                :invalid-text="parseErrors('Pilih Aktivitas', $v.form.perkeniFormulaPhysicalActivity, customMessageErrors)"
                is-inline
                spacing="8"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.perkeniFormulaPhysicalActivity"
                type="number"
                label="% Aktivitas Fisik"
                placeholder="% Aktivitas Fisik"
                is-required
                is-disabled
                right-element="%"
                custom-padding-right-input="50px"
                :is-invalid="$v.form.perkeniFormulaPhysicalActivity.$invalid"
              />
            </c-box>
          </c-flex>
          <c-flex
            align-items="flex-start"
            gap="12px"
          >
            <c-box
              flex-grow="1"
            >
              <FormRadio
                v-model="form.perkeniFormulaAgeFactor"
                label="Pilih Kategori Umur"
                is-required
                :options="constants.ageFactorOptions"
                :is-invalid="isInvalidField($v.form.perkeniFormulaAgeFactor)"
                :invalid-text="parseErrors('Pilih Kategori Umur', $v.form.perkeniFormulaAgeFactor, customMessageErrors)"
                is-inline
                spacing="8"
              />
            </c-box>
            <c-box
              width="300px"
              flex-shrink="0"
            >
              <FormInput
                v-model="form.perkeniFormulaAgeFactor"
                type="number"
                label="Faktor Umur (%)"
                placeholder="Faktor Umur (%)"
                is-required
                is-disabled
                right-element="%"
                custom-padding-right-input="50px"
                :is-invalid="$v.form.perkeniFormulaAgeFactor.$invalid"
              />
            </c-box>
          </c-flex>
          <FormRadio
            v-model="form.perkeniFormulaMetabolicStressFactor"
            label="Faktor Stres Metabolik (%)"
            is-required
            :options="constants.stressFactorOptions"
            :is-invalid="isInvalidField($v.form.perkeniFormulaMetabolicStressFactor)"
            :invalid-text="parseErrors('Faktor Stres Metabolik (%)', $v.form.perkeniFormulaMetabolicStressFactor, customMessageErrors)"
            is-inline
            spacing="16"
          />
          <FormRadio
            v-model="form.perkeniFormulaMalnutritionStatusFactor"
            label="Faktor Status Gizi Kurang (%)"
            is-required
            :options="constants.statusFactorOptions"
            :is-invalid="isInvalidField($v.form.perkeniFormulaMalnutritionStatusFactor)"
            :invalid-text="parseErrors('Faktor Status Gizi Kurang (%)', $v.form.perkeniFormulaMalnutritionStatusFactor, customMessageErrors)"
            is-inline
            spacing="16"
          />
          <FormRadio
            v-model="form.perkeniFormulaOvernutritionStatusFactor"
            label="Faktor Status Gizi Lebih (%)"
            is-required
            :options="constants.statusFactorOptions"
            :is-invalid="isInvalidField($v.form.perkeniFormulaOvernutritionStatusFactor)"
            :invalid-text="parseErrors('Faktor Status Gizi Lebih (%)', $v.form.perkeniFormulaOvernutritionStatusFactor, customMessageErrors)"
            is-inline
            spacing="16"
          />
          <FormRadio
            v-model="form.perkeniFormulaMaternalCondition"
            label="Kondisi Keibuan (kkal)"
            is-required
            :options="constants.maternalConditionOptions"
            :is-invalid="isInvalidField($v.form.perkeniFormulaMaternalCondition)"
            :invalid-text="parseErrors('Kondisi Keibuan (kkal)', $v.form.perkeniFormulaMaternalCondition, customMessageErrors)"
            is-inline
            spacing="8"
          />
          <c-grid
            template-columns="repeat(2, minmax(0, 1fr))"
            gap="16px"
          >
            <FormInput
              v-model="form.perkeniFormulaMaternalCondition"
              type="number"
              label="Tambahan Kalori Kondisi Keibuan (kkal)"
              placeholder="Tambahan Kalori Kondisi Keibuan (kkal)"
              is-required
              is-disabled
              right-element="kkal"
              custom-padding-right-input="70px"
              :is-invalid="$v.form.perkeniFormulaMaternalCondition.$invalid"
              is-without-margin-bottom
            />
            <FormInput
              v-model="form.perkeniFormulaTotalEnergyNeeded"
              type="number"
              label="Total Kebutuhan Energi (kkal)"
              placeholder="Total Kebutuhan Energi (kkal)"
              is-required
              is-disabled
              right-element="kkal"
              custom-padding-right-input="70px"
              :is-invalid="isInvalidField($v.form.perkeniFormulaTotalEnergyNeeded)"
              invalid-text="Selesaikan isian semua data terlebih dahulu"
              is-without-margin-bottom
            />
          </c-grid>
        </c-box>
        <c-divider v-if="form.calculationType === 'adult' && form.formula === 'perkeniFormula'" />
        <c-box
          v-if="form.calculationType === 'adult' && form.formula === 'ckdFormula'"
        >
          <FormRadio
            v-model="form.ckdFormulaWeightChoice"
            label="Pilihan Berat Badan"
            is-required
            :options="constants.weightOptions"
            :is-invalid="isInvalidField($v.form.ckdFormulaWeightChoice)"
            :invalid-text="parseErrors('Pilihan Berat Badan', $v.form.ckdFormulaWeightChoice, customMessageErrors)"
            is-inline
            spacing="8"
          />
          <FormRadio
            v-model="form.ckdFormulaCkdEnergyRequirement"
            label="Kebutuhan Energi per kg BB"
            is-required
            :options="constants.ckdEnergyRequirementOptions"
            :is-invalid="isInvalidField($v.form.ckdFormulaCkdEnergyRequirement)"
            :invalid-text="parseErrors('Kebutuhan Energi per kg BB', $v.form.ckdFormulaCkdEnergyRequirement, customMessageErrors)"
            is-inline
            spacing="8"
          />
          <FormInput
            v-model="form.ckdFormulaTotalEnergyNeeded"
            type="number"
            label="Total Kebutuhan Energi (kkal)"
            placeholder="Total Kebutuhan Energi (kkal)"
            is-required
            is-disabled
            right-element="kkal"
            custom-padding-right-input="70px"
            :is-invalid="isInvalidField($v.form.ckdFormulaTotalEnergyNeeded)"
            invalid-text="Selesaikan isian semua data terlebih dahulu"
            is-without-margin-bottom
          />
        </c-box>
        <c-divider v-if="form.calculationType === 'adult' && form.formula === 'ckdFormula'" />
      </FormAccordionList>
      <c-grid
        w="100%"
        :template-columns="['repeat(2, 1fr)']"
        :gap="['16px']"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="onOpenModalCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="handleChangeStep(constants.tabs['nutritional-intake'], false)"
        >
          Selanjutnya
        </c-button>
      </c-grid>

      <BaseModal
        :is-open="!!isOpenModalCancel"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 24px 20px 24px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
            >
              Apakah anda yakin<br>ingin membatalkan pengisian formulir?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCloseModalCancel"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onConfirmModalCancel"
            >
              Konfirmasi
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>

    <!-- MODAL -->
    <ModalSuccessSaveData
      :is-open="isOpenModalSuccessSaveData"
      @close="isOpenModalSuccessSaveData = false"
    />

    <BaseModal
      :is-open="!!isOpenModalNext"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 16px 20px 16px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="8px"
          >
            Data kamu belum lengkap
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px"
            color="neutral.darkGray"
          >
            Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="onCancelNext"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="onStepTo"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- Modal back to preview -->
    <BaseModal
      :is-open="isOpenModalBackToPreview"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            Apakah anda yakin<br>ingin membatalkan pengisian formulir?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="isOpenModalBackToPreview = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackToPreview"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-warning.svg')"
      title="Gagal Menyimpan Data"
      description="Terdapat kesalahan data, coba tinjau ulang datamu"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import _ from 'lodash'
import equal from 'fast-deep-equal'
import FormAccordionList from '@/views/client/kuisioner/forms/form-accordion/list.vue'
import FormAccordionListItem from '@/views/client/kuisioner/forms/form-accordion/list-item.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2'
import FormInput from '@/views/client/kuisioner/forms/form-input-2'
import FormRadio from '@/views/client/kuisioner/forms/form-radio-2'
import { required, requiredIf, numeric, decimal } from 'vuelidate/lib/validators'
import {
  calculationTypes,
  childAgeRanges,
  adultFormulas,
  childFormulas,
  childFormulas2,
  conclusionOptions,
  weightOptions,
  physicalActivityOptions,
  maternalConditionOptions,
  ageFactorOptions,
  ckdEnergyRequirementOptions,
  monthFormulas,
  yearMaleFormulas,
  yearFemaleFormulas,
  stressFactorOptions,
  statusFactorOptions,
  tabs,
  caloriesOptions,
} from '@/constants/meal-plans'
import { nutritionalStatusAdult, nutritionalStatusChild, mifflinStJeorFormula, perkeniFormula, ckdFormula, totalEnergyChild } from '@/utils/meal-plans'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import NutritionalProfileSteps from '@/components/meal-plan/_widgets/steps.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import ModalSuccessSaveData
  from '@/components/meal-plan/_widgets/modal-success-save-data.vue'
import { CBox, CButton, CFlex, CImage } from '@chakra-ui/vue'
import EventBus from '@/utils/event-bus'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { mapGetters, mapMutations } from 'vuex'
import StatusLabel from '@/components/meal-plan/_widgets/status-label.vue'

export default {
  components: {
    StatusLabel,
    CButton,
    CFlex,
    CBox, CImage,
    ModalSuccessSaveData,
    FormAccordionList,
    FormAccordionListItem,
    FormSelect,
    FormInput,
    FormRadio,
    BaseModal,
    BaseText,
    BaseButton,
    NutritionalProfileSteps,
    ModalSimpleInfo,
  },
  props: {
    draft: {
      type: [Object, null],
      default: null,
    },
    isDraftExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      isOpenModalBackToPreview: false,
      isOpenModalNext: false,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      basicData: null,
      isEditWeight: false,
      isEditHeight: false,
      formDuplicate: null,
      form: {
        // Form general
        name: null,
        age: null,
        birthDate: null,
        gender: null,
        weight: null,
        height: null,
        calculationType: null, // 'child' | 'adult'
        imt: null,
  
        // Form child 
        correctedHeight: null,
        childAgeRange: null, // 'year' | 'month'
        zScoreWeightPerAge: null,
        zScoreHeightPerAge: null,
        zScoreHeightPerWeight: null,
        zScoreImtPerAge: null,
        zScoreConclusion: null,
        childAgeRangeMonthNutritionalStatusWeightPerAge: null,
        childAgeRangeMonthNutritionalStatusHeightPerAge: null,
        childAgeRangeMonthNutritionalStatusHeightPerWeight: null,
        childAgeRangeMonthNutritionalStatusImtPerAge: null,
        childAgeRangeMonthNutritionalStatusConclusion: null,
        childAgeRangeMonthNutritionalStatusConclusionChoice: null, // 'nutritionalStatusHeightPerWeight' | 'nutritionalStatusImtPerAge'
        childAgeRangeYearNutritionalStatusImtPerAge: null,
        childIdealWeight: null,
        childIdealHeight: null,
        ageAccordingHeight: null,
        monthFormulaCalculationAccordingAgeAndHeight: null,
        monthFormulaTotalEnergyNeeded: null,
        yearMaleFormulaCalculationAccordingAgeAndHeight: null,
        yearFemaleFormulaCalculationAccordingAgeAndHeight: null,
        yearFormulaTotalEnergyNeeded: null,
        
        // Form adult 
        nutritionalStatus: null,
        minIdealWeight: null,
        maxIdealWeight: null,
        medianIdealWeight: null,
        broccaIdealWeight: null,
        adjustedIdealWeight: null,
        mifflinStJeorFormulaWeightChoice: null,
        mifflinStJeorFormulaBassEnergyRequirement: null,
        mifflinStJeorFormulaPhysicalActivity: null,
        mifflinStJeorFormulaMaternalCondition: null,
        mifflinStJeorFormulaCalorieChoice: null,
        mifflinStJeorFormulaCalorieDeficit: null,
        mifflinStJeorFormulaTotalEnergyNeeded: null,
        perkeniFormulaWeightChoice: null,
        perkeniFormulaBassEnergyRequirement: null,
        perkeniFormulaPhysicalActivity: null,
        perkeniFormulaAgeFactor: null,
        perkeniFormulaMetabolicStressFactor: null,
        perkeniFormulaMalnutritionStatusFactor: null,
        perkeniFormulaOvernutritionStatusFactor: null,
        perkeniFormulaMaternalCondition: null,
        perkeniFormulaTotalEnergyNeeded: null,
        ckdFormulaWeightChoice: null,
        ckdFormulaCkdEnergyRequirement: null,
        ckdFormulaTotalEnergyNeeded: null,
  
        // Form child and adult
        formula: null, // child: 'monthFormula' | 'yearMaleFormula' | 'yearFemaleFormula' | 'manualFormula', adult: 'mifflinStJeorFormula' | 'perkeniFormula' | 'ckdFormula' | 'manualFormula'
        manualFormulaTotalEnergyNeeded: null,
      },
      
      // Other
      childAgeRangeMonthNutritionalStatusWeightPerAgeOptions: [],
      childAgeRangeMonthNutritionalStatusHeightPerAgeOptions: [],
      childAgeRangeMonthNutritionalStatusHeightPerWeightOptions: [],
      childAgeRangeMonthNutritionalStatusImtPerAgeOptions: [],
      childAgeRangeYearNutritionalStatusImtPerAgeOptions: [],
      isLoadingData: true,
      isLoadingSave: false,
      isOpenModalCancel: false,

      stepTo: {
        tab: '',
        savePosition: false,
      },
    }
  },
  validations: {
    form: {
      name: { required },
      age: { required, numeric },
      birthDate: { required },
      gender: { required },
      weight: { required, decimal },
      height: { required, decimal },
      calculationType: { required },
      imt: { 
        required: requiredIf((model) => model.calculationType === 'adult' || model.calculationType === 'child'),
      },
      correctedHeight: { 
        required: requiredIf((model) => model.calculationType === 'child'),
      },
      childAgeRange: { 
        required: requiredIf((model) => model.calculationType === 'child'),
      },
      childAgeRangeMonthNutritionalStatusWeightPerAge: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeMonthNutritionalStatusHeightPerAge: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeMonthNutritionalStatusHeightPerWeight: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeMonthNutritionalStatusImtPerAge: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeMonthNutritionalStatusConclusionChoice: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeMonthNutritionalStatusConclusion: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '0 - 60 bulan'),
      },
      childAgeRangeYearNutritionalStatusImtPerAge: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.childAgeRange === '> 5 - 18 tahun'),
      },
      childIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'child'),
      },
      childIdealHeight: { 
        required: requiredIf((model) => model.calculationType === 'child'),
      },
      ageAccordingHeight: { 
        required: requiredIf((model) => model.calculationType === 'child'),
      },
      monthFormulaCalculationAccordingAgeAndHeight: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.formula === 'monthFormula'),
      },
      monthFormulaTotalEnergyNeeded: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.formula === 'monthFormula'),
      },
      yearMaleFormulaCalculationAccordingAgeAndHeight: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.formula === 'yearMaleFormula'),
      },
      yearFemaleFormulaCalculationAccordingAgeAndHeight: { 
        required: requiredIf((model) => model.calculationType === 'child' && model.formula === 'yearFemaleFormula'),
      },
      yearFormulaTotalEnergyNeeded: { 
        required: requiredIf((model) => model.calculationType === 'child' && (model.formula === 'yearMaleFormula' || model.formula === 'yearFemaleFormula')),
      },
      nutritionalStatus: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      minIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      maxIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      medianIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      broccaIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      adjustedIdealWeight: { 
        required: requiredIf((model) => model.calculationType === 'adult'),
      },
      formula: { 
        required,
      },
      mifflinStJeorFormulaWeightChoice: { 
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      mifflinStJeorFormulaBassEnergyRequirement: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      mifflinStJeorFormulaPhysicalActivity: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      mifflinStJeorFormulaMaternalCondition: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      mifflinStJeorFormulaCalorieChoice: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      mifflinStJeorFormulaCalorieDeficit: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
        numeric,
      },
      mifflinStJeorFormulaTotalEnergyNeeded: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'mifflinStJeorFormula'),
      },
      perkeniFormulaWeightChoice: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaBassEnergyRequirement: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaPhysicalActivity: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaAgeFactor: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaMetabolicStressFactor: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaMalnutritionStatusFactor: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaOvernutritionStatusFactor: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaMaternalCondition: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      perkeniFormulaTotalEnergyNeeded: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'perkeniFormula'),
      },
      ckdFormulaWeightChoice: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'ckdFormula'),
      },
      ckdFormulaCkdEnergyRequirement: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'ckdFormula'),
      },
      ckdFormulaTotalEnergyNeeded: {
        required: requiredIf((model) => model.calculationType === 'adult' && model.formula === 'ckdFormula'),
      },
      manualFormulaTotalEnergyNeeded: {
        required: requiredIf((model) => (model.formula === 'manualFormula')),
      },
    },
  },
  computed: {
    ...mapGetters({
      saveState: 'general/saveState',
    }),
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
            state: 'success',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
            state: 'failed',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
            state: 'loading',
          }
      }
    },
    isSaveDisabled() {
      if (!this.hasChanges) {
        return true
      }
      return this.isLoadingSave
    },
    hasChanges() {
      return !_.isEqual(this.form, this.formDuplicate)
    },
    constants() {
      return {
        calculationTypes,
        childAgeRanges,
        adultFormulas,
        childFormulas,
        childFormulas2,
        conclusionOptions,
        weightOptions,
        physicalActivityOptions,
        maternalConditionOptions,
        ageFactorOptions,
        ckdEnergyRequirementOptions,
        monthFormulas,
        yearMaleFormulas,
        yearFemaleFormulas,
        stressFactorOptions,
        statusFactorOptions,
        tabs,
        caloriesOptions,
      }
    },
    isSectionDataDiriValid() {
      return !this.$v.form.name.$invalid
            && !this.$v.form.age.$invalid
            && !this.$v.form.gender.$invalid
            && !this.$v.form.weight.$invalid
            && !this.$v.form.height.$invalid
    },
    isSectionJenisPerhitunganValid() {
      return !this.$v.form.calculationType.$invalid
    },
    isSectionStatusGiziAnakValid() {
      return !this.$v.form.correctedHeight.$invalid
            && !this.$v.form.imt.$invalid
            && !this.$v.form.childAgeRange.$invalid
    },
    isSectionKebutuhanEnergiAnakValid() {
      if (this.form.formula === 'manualFormula') {
        return !this.$v.form.childIdealWeight.$invalid
              && !this.$v.form.childIdealHeight.$invalid
              && !this.$v.form.ageAccordingHeight.$invalid
              && !this.$v.form.formula.$invalid
              && this.isSectionManualFormulaValid
      } else {
        return !this.$v.form.childIdealWeight.$invalid
              && !this.$v.form.childIdealHeight.$invalid
              && !this.$v.form.ageAccordingHeight.$invalid
              && !this.$v.form.formula.$invalid  
      }
    },
    isSectionStatusGiziAnak060BulanValid() {
      return !this.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge.$invalid
            && !this.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge.$invalid
            && !this.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight.$invalid
            && !this.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge.$invalid
            && !this.$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice.$invalid
            && !this.$v.form.childAgeRangeMonthNutritionalStatusConclusion.$invalid
    },
    isSectionStatusGiziAnak518TahunValid() {
      return !this.$v.form.childAgeRangeYearNutritionalStatusImtPerAge.$invalid
    },
    isSectionRumus010TahunValid() {
      return !this.$v.form.monthFormulaCalculationAccordingAgeAndHeight.$invalid
            && !this.$v.form.monthFormulaTotalEnergyNeeded.$invalid
    },
    isSectionRumusLakiLaki1118TahunValid() {
      return !this.$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight.$invalid
            && !this.$v.form.yearFormulaTotalEnergyNeeded.$invalid
    },
    isSectionRumusPerempuan1118TahunValid() {
      return !this.$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight.$invalid
            && !this.$v.form.yearFormulaTotalEnergyNeeded.$invalid
    },
    isSectionStatusGiziDewasaValid() {
      return !this.$v.form.imt.$invalid
            && !this.$v.form.nutritionalStatus.$invalid
            && !this.$v.form.minIdealWeight.$invalid
            && !this.$v.form.maxIdealWeight.$invalid
            && !this.$v.form.medianIdealWeight.$invalid
            && !this.$v.form.broccaIdealWeight.$invalid
            && !this.$v.form.adjustedIdealWeight.$invalid
    },
    isSectionFormulaValid() {
      return !this.$v.form.formula.$invalid
    },
    isSectionMifflinFormulaValid() {
      return !this.$v.form.mifflinStJeorFormulaWeightChoice.$invalid
            && !this.$v.form.mifflinStJeorFormulaBassEnergyRequirement.$invalid
            && !this.$v.form.mifflinStJeorFormulaPhysicalActivity.$invalid
            && !this.$v.form.mifflinStJeorFormulaMaternalCondition.$invalid
            && !this.$v.form.mifflinStJeorFormulaCalorieChoice.$invalid
            && !this.$v.form.mifflinStJeorFormulaCalorieDeficit.$invalid
            && !this.$v.form.mifflinStJeorFormulaTotalEnergyNeeded.$invalid
    },
    isSectionPerkeniFormulaValid() {
      return !this.$v.form.perkeniFormulaWeightChoice.$invalid
            && !this.$v.form.perkeniFormulaBassEnergyRequirement.$invalid
            && !this.$v.form.perkeniFormulaPhysicalActivity.$invalid
            && !this.$v.form.perkeniFormulaAgeFactor.$invalid
            && !this.$v.form.perkeniFormulaMetabolicStressFactor.$invalid
            && !this.$v.form.perkeniFormulaMalnutritionStatusFactor.$invalid
            && !this.$v.form.perkeniFormulaOvernutritionStatusFactor.$invalid
            && !this.$v.form.perkeniFormulaMaternalCondition.$invalid
            && !this.$v.form.perkeniFormulaTotalEnergyNeeded.$invalid
    },
    isSectionCkdFormulaValid() {
      return !this.$v.form.ckdFormulaWeightChoice.$invalid
            && !this.$v.form.ckdFormulaCkdEnergyRequirement.$invalid
            && !this.$v.form.ckdFormulaTotalEnergyNeeded.$invalid
    },
    isSectionManualFormulaValid() {
      return !this.$v.form.manualFormulaTotalEnergyNeeded.$invalid
    },
    isSectionKebutuhanEnergiDewasaValid() {
      if (this.form.formula === 'manualFormula') {
        return this.isSectionFormulaValid && this.isSectionManualFormulaValid
      } else {
        return this.isSectionFormulaValid
      }
    },
    age() {
      if (!this.form.birthDate) return null
      return new Date().getFullYear() - new Date(this.form.birthDate).getFullYear()
    },
    isDisabledSubmit() {
      return this.$v.form.$invalid
    },
    customMessageErrors() {
      return {
        required: '{{fieldName}} wajib diisi',
      }
    },
    isValidAllFormProfilGizi() {
      const isAllValid = isAllFormValid({
        nutritionalConditions: !this.$v?.$invalid,
        nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
        bodyConditions: this.draft?.bodyConditions?.validation === 1,
        targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
      })
      return isAllValid
    },
  },
  watch: {
    hasChanges: {
      handler: _.debounce(function(val) {
        if (val) {
          this.formDuplicate = _.cloneDeep(this.form)
          this.onSaveDraft()
        } else if (this.saveState === null) {
          this.setSaveState(true)
        }
      }, 3000),
    },
    form: {
      handler() {
        this.setSaveState(null)
        const data = {
          form: 'nutritional-condition',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)
      },
      deep: true,
    },
    'form.weight': {
      handler() {
        if (this.form.calculationType === 'adult') {
          this.updateNutritionalStatusAdult()
        } else if (this.form.calculationType === 'child') [
          this.updateNutritionalStatusChild(),
        ]
      },
    },
    'form.height': {
      handler() {
        if (this.form.calculationType === 'adult') {
          this.updateNutritionalStatusAdult()
          if (this.form.formula === 'mifflinStJeorFormula') {
            this.updateMifflinStJeorFormulaBassEnergyRequirement()
          } else if (this.form.formula === 'perkeniFormula') {
            this.updatePerkeniFormulaTotalEnergyNeeded()
          }
        }
      },
    },
    'form.correctedHeight': {
      handler() {
        if (this.form.calculationType === 'child') {
          this.updateNutritionalStatusChild()
        }
      },
    },
    'form.adjustedIdealWeight': {
      handler() {
        switch(this.form.formula) {
          case 'mifflinStJeorFormula':
            this.updateMifflinStJeorFormulaBassEnergyRequirement()
            this.updateMifflinStJeorFormulaTotalEnergyNeeded()
            break
          case 'perkeniFormula':
            this.updatePerkeniFormulaBassEnergyRequirement()
            this.updatePerkeniFormulaTotalEnergyNeeded()
            break
          case 'ckdFormula':
            this.updateCkdFormulaTotalEnergyNeeded()
            break
        }
      },
    },
    'form.calculationType': {
      async handler(val) {
        if (this.isLoadingData) return
        if (val === 'adult') {
          this.updateNutritionalStatusAdult()
        }
        this.onChangeCalculationType()
      },
    },
    'form.formula': {
      handler(v1, v2) {
        if (this.isLoadingData) return
        if (equal(v1, v2)) return
        if (this.form.calculationType === 'adult') {
          this.onResetFormFormulaAdult()
        } else if (this.form.calculationType === 'child') {
          this.onResetFormFormulaChild()
        }
      },
    },
    'form.childIdealWeight': {
      handler() {
        if (this.form.formula === 'monthFormula') {
          this.updateMonthFormulaTotalEnergyNeeded()
        } else if (this.form.formula === 'yearMaleFormula' || this.form.formula === 'yearFemaleFormula') {
          this.updateYearFormulaTotalEnergyNeeded()
        }
      },
    },
    'form.monthFormulaCalculationAccordingAgeAndHeight': {
      handler() {
        this.updateMonthFormulaTotalEnergyNeeded()
      },
    },
    'form.yearMaleFormulaCalculationAccordingAgeAndHeight': {
      handler() {
        this.updateYearFormulaTotalEnergyNeeded()
      },
    },
    'form.yearFemaleFormulaCalculationAccordingAgeAndHeight': {
      handler() {
        this.updateYearFormulaTotalEnergyNeeded()
      },
    },
    'form.mifflinStJeorFormulaWeightChoice': {
      immediate: true,
      handler() {
        this.updateMifflinStJeorFormulaBassEnergyRequirement()
      },
    },
    'form.mifflinStJeorFormulaBassEnergyRequirement': {
      handler() {
        this.updateMifflinStJeorFormulaTotalEnergyNeeded()
      },
    },
    'form.mifflinStJeorFormulaPhysicalActivity': {
      handler() {
        this.updateMifflinStJeorFormulaTotalEnergyNeeded()
      },
    },
    'form.mifflinStJeorFormulaMaternalCondition': {
      handler() {
        this.updateMifflinStJeorFormulaTotalEnergyNeeded()
      },
    },
    'form.mifflinStJeorFormulaCalorieChoice': {
      handler() {
        this.updateMifflinStJeorFormulaTotalEnergyNeeded()
      },
    },
    'form.mifflinStJeorFormulaCalorieDeficit': {
      handler() {
        this.updateMifflinStJeorFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaWeightChoice': {
      handler() {
        this.updatePerkeniFormulaBassEnergyRequirement()
      },
    },
    'form.perkeniFormulaPhysicalActivity': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaAgeFactor': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaMetabolicStressFactor': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaMalnutritionStatusFactor': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaOvernutritionStatusFactor': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.perkeniFormulaMaternalCondition': {
      handler() {
        this.updatePerkeniFormulaTotalEnergyNeeded()
      },
    },
    'form.ckdFormulaWeightChoice': {
      handler() {
        this.updateCkdFormulaTotalEnergyNeeded()
      },
    },
    'form.ckdFormulaCkdEnergyRequirement': {
      handler() {
        this.updateCkdFormulaTotalEnergyNeeded()
      },
    },
    'form.childAgeRange': {
      async handler(v1, v2) {
        if (v1 && v2 || !v1) {
          this.form.formula = null
        }
        if (!this.isLoadingData) {
          this.onResetFormChildAgeRange()
        }
        if (v1) {
          const res = await reqNutriMealPlan.getFormOptConditions(this.$store.getters.axios, { 
            age: v1, 
          })
          if (res.data?.data && res.data?.data.length > 0) {
            res.data?.data.forEach((it) => {
              switch(it?.params) {
                case 'Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)':
                  this.childAgeRangeMonthNutritionalStatusHeightPerAgeOptions = it?.options ?? []
                  break
                case 'Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)':
                  this.childAgeRangeMonthNutritionalStatusHeightPerWeightOptions = it?.options ?? []
                  break
                case 'Z-Score Berat Badan/Umur (BB/U)':
                  this.childAgeRangeMonthNutritionalStatusWeightPerAgeOptions = it?.options ?? []
                  break
                case 'Z-Score Indeks Massa Tubuh/Umur (IMT/U)':
                  if (v1 === '0 - 60 bulan') {
                    this.childAgeRangeMonthNutritionalStatusImtPerAgeOptions = it?.options ?? []
                  } else {
                    this.childAgeRangeYearNutritionalStatusImtPerAgeOptions = it?.options ?? []
                  }
                  break
              }
            })
          }
        }
      },
    },
    'form.childAgeRangeMonthNutritionalStatusWeightPerAge': {
      handler(val) {
        const item = this.childAgeRangeMonthNutritionalStatusWeightPerAgeOptions.find((it) => it?.value === val)
        if (item) {
          this.form.zScoreWeightPerAge = item?.label
        }
      },
    },
    'form.childAgeRangeMonthNutritionalStatusHeightPerAge': {
      handler(val) {
        const item = this.childAgeRangeMonthNutritionalStatusHeightPerAgeOptions.find((it) => it?.value === val)
        if (item) {
          this.form.zScoreHeightPerAge = item?.label
        }
      },
    },
    'form.childAgeRangeMonthNutritionalStatusHeightPerWeight': {
      handler(val) {
        this.updateNutritionalStatusConclusion()
        const item = this.childAgeRangeMonthNutritionalStatusHeightPerWeightOptions.find((it) => it?.value === val)
        if (item) {
          this.form.zScoreHeightPerWeight = item?.label
        }
      },
    },
    'form.childAgeRangeMonthNutritionalStatusImtPerAge': {
      handler(val) {
        this.updateNutritionalStatusConclusion()
        const item = this.childAgeRangeMonthNutritionalStatusImtPerAgeOptions.find((it) => it?.value === val)
        if (item) {
          this.form.zScoreImtPerAge = item?.label
        }
      },
    },
    'form.childAgeRangeMonthNutritionalStatusConclusionChoice': {
      handler() {
        this.updateNutritionalStatusConclusion()
      },
    },
    'form.childAgeRangeYearNutritionalStatusImtPerAge': {
      handler(val) {
        const item = this.childAgeRangeYearNutritionalStatusImtPerAgeOptions.find((it) => it?.value === val)
        if (item) {
          this.form.zScoreImtPerAge = item?.label
        }
      },
    },
  },
  async mounted() {
    await this.getBasicData()
    if (!!this.draft?.nutritionalConditions && !_.isEmpty(this.draft?.nutritionalConditions)) {
      this.form.name = this.draft?.nutritionalConditions?.name ? this.draft?.nutritionalConditions?.name : this.basicData?.name
      this.form.age = this.draft?.nutritionalConditions?.age ? this.draft?.nutritionalConditions?.age : this.basicData?.age
      this.form.birthDate = this.draft?.nutritionalConditions?.birthDate ? this.draft?.nutritionalConditions?.birthDate : this.basicData?.birthDate
      this.form.gender = this.draft?.nutritionalConditions?.gender ? this.draft?.nutritionalConditions?.gender : this.basicData?.gender
      this.form.weight = this.draft?.nutritionalConditions?.weight ? this.draft?.nutritionalConditions?.weight : this.basicData?.weight
      this.form.height = this.draft?.nutritionalConditions?.height ? this.draft?.nutritionalConditions?.height : this.basicData?.height
      this.form.calculationType = this.draft?.nutritionalConditions?.calculationType ? this.draft?.nutritionalConditions?.calculationType : null
      this.form.imt = this.draft?.nutritionalConditions?.imt ? this.draft?.nutritionalConditions?.imt : null
      this.form.formula = this.draft?.nutritionalConditions?.formula ? this.draft?.nutritionalConditions?.formula : null

      this.form.correctedHeight = this.draft?.nutritionalConditions?.correctedHeight ? this.draft?.nutritionalConditions?.correctedHeight : null
      this.form.childAgeRange = this.draft?.nutritionalConditions?.childAgeRange ? this.draft?.nutritionalConditions?.childAgeRange : null
      this.form.calculationType = this.draft?.nutritionalConditions?.calculationType ? this.draft?.nutritionalConditions?.calculationType : null

      if (this.draft?.nutritionalConditions?.childAgeRange === '0 - 60 bulan') {
        this.form.childAgeRangeMonthNutritionalStatusWeightPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusWeightPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusWeightPerAge : null
        this.form.childAgeRangeMonthNutritionalStatusHeightPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusHeightPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusHeightPerAge : null
        this.form.childAgeRangeMonthNutritionalStatusHeightPerWeight = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusHeightPerWeight ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusHeightPerWeight : null
        this.form.childAgeRangeMonthNutritionalStatusImtPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusImtPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusImtPerAge : null
        this.form.childAgeRangeMonthNutritionalStatusConclusionChoice = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusConclusionChoice ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusConclusionChoice : null
        this.form.childAgeRangeMonthNutritionalStatusConclusion = this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusConclusion ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.nutritionalStatusConclusion : null
        this.form.zScoreWeightPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreWeightPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreWeightPerAge : null
        this.form.zScoreHeightPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreHeightPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreHeightPerAge : null
        this.form.zScoreHeightPerWeight = this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreHeightPerWeight ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreHeightPerWeight : null
        this.form.zScoreImtPerAge = this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreImtPerAge ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreImtPerAge : null
        this.form.zScoreConclusion = this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreConclusion ? this.draft?.nutritionalConditions?.childAgeRangeMonth?.zScoreConclusion : null
      } else if (this.draft?.nutritionalConditions?.childAgeRange === '> 5 - 18 tahun') {
        this.form.childAgeRangeYearNutritionalStatusImtPerAge = this.draft?.nutritionalConditions?.childAgeRangeYear?.nutritionalStatusImtPerAge ? this.draft?.nutritionalConditions?.childAgeRangeYear?.nutritionalStatusImtPerAge : null
        this.form.zScoreImtPerAge = this.draft?.nutritionalConditions?.childAgeRangeYear?.zScoreImtPerAge ? this.draft?.nutritionalConditions?.childAgeRangeYear?.zScoreImtPerAge : null
      }

      if (this.draft?.nutritionalConditions?.calculationType === 'child') {
        this.form.childIdealWeight = this.draft?.nutritionalConditions?.childIdealWeight ? this.draft?.nutritionalConditions?.childIdealWeight : null
        this.form.childIdealHeight = this.draft?.nutritionalConditions?.childIdealHeight ? this.draft?.nutritionalConditions?.childIdealHeight : null
        this.form.ageAccordingHeight = this.draft?.nutritionalConditions?.ageAccordingHeight ? this.draft?.nutritionalConditions?.ageAccordingHeight : null
      } else if (this.draft?.nutritionalConditions?.calculationType === 'adult') {
        this.form.nutritionalStatus = this.draft?.nutritionalConditions?.nutritionalStatus ? this.draft?.nutritionalConditions?.nutritionalStatus : null
        this.form.minIdealWeight = this.draft?.nutritionalConditions?.minIdealWeight ? this.draft?.nutritionalConditions?.minIdealWeight : null
        this.form.maxIdealWeight = this.draft?.nutritionalConditions?.maxIdealWeight ? this.draft?.nutritionalConditions?.maxIdealWeight : null
        this.form.medianIdealWeight = this.draft?.nutritionalConditions?.medianIdealWeight ? this.draft?.nutritionalConditions?.medianIdealWeight : null
        this.form.broccaIdealWeight = this.draft?.nutritionalConditions?.broccaIdealWeight ? this.draft?.nutritionalConditions?.broccaIdealWeight : null
        this.form.adjustedIdealWeight = this.draft?.nutritionalConditions?.adjustedIdealWeight ? this.draft?.nutritionalConditions?.adjustedIdealWeight : null
      }

      if (this.draft?.nutritionalConditions?.formula === 'monthFormula') {
        this.form.monthFormulaCalculationAccordingAgeAndHeight = this.draft?.nutritionalConditions?.monthFormula?.calculationAccordingAgeAndHeight ? this.draft?.nutritionalConditions?.monthFormula?.calculationAccordingAgeAndHeight : null
        this.form.monthFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.monthFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.monthFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'yearMaleFormula') {
        this.form.yearMaleFormulaCalculationAccordingAgeAndHeight = this.draft?.nutritionalConditions?.yearMaleFormula?.calculationAccordingAgeAndHeight ? this.draft?.nutritionalConditions?.yearMaleFormula?.calculationAccordingAgeAndHeight : null
        this.form.yearFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.yearMaleFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.yearMaleFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'yearFemaleFormula') {
        this.form.yearFemaleFormulaCalculationAccordingAgeAndHeight = this.draft?.nutritionalConditions?.yearFemaleFormula?.calculationAccordingAgeAndHeight ? this.draft?.nutritionalConditions?.yearFemaleFormula?.calculationAccordingAgeAndHeight : null
        this.form.yearFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.yearFemaleFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.yearFemaleFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'mifflinStJeorFormula') {
        this.form.mifflinStJeorFormulaWeightChoice = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.weightChoice ? this.draft?.nutritionalConditions?.mifflinStJeorFormula?.weightChoice : null
        this.form.mifflinStJeorFormulaBassEnergyRequirement = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.bassEnergyRequirement ? this.draft?.nutritionalConditions?.mifflinStJeorFormula?.bassEnergyRequirement : null
        this.form.mifflinStJeorFormulaPhysicalActivity = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.physicalActivity ? this.draft?.nutritionalConditions?.mifflinStJeorFormula?.physicalActivity : null
        this.form.mifflinStJeorFormulaMaternalCondition = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.maternalCondition ? this.draft?.nutritionalConditions?.mifflinStJeorFormula?.maternalCondition : null

        const calorieChoice = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.calorieChoice
        const calorieDeficit = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.calorieDeficit
        this.form.mifflinStJeorFormulaCalorieChoice = calorieChoice || null
        this.form.mifflinStJeorFormulaCalorieDeficit = calorieDeficit 
          ? calorieChoice === 'surplus' 
            ? `${-Number(calorieDeficit)}` 
            : calorieDeficit 
          : null

        this.form.mifflinStJeorFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.mifflinStJeorFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.mifflinStJeorFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'perkeniFormula') {
        this.form.perkeniFormulaWeightChoice = this.draft?.nutritionalConditions?.perkeniFormula?.weightChoice ? this.draft?.nutritionalConditions?.perkeniFormula?.weightChoice : null
        this.form.perkeniFormulaBassEnergyRequirement = this.draft?.nutritionalConditions?.perkeniFormula?.bassEnergyRequirement ? this.draft?.nutritionalConditions?.perkeniFormula?.bassEnergyRequirement : null
        this.form.perkeniFormulaPhysicalActivity = this.draft?.nutritionalConditions?.perkeniFormula?.physicalActivity ? this.draft?.nutritionalConditions?.perkeniFormula?.physicalActivity : null
        this.form.perkeniFormulaAgeFactor = this.draft?.nutritionalConditions?.perkeniFormula?.ageFactor ? this.draft?.nutritionalConditions?.perkeniFormula?.ageFactor : null
        this.form.perkeniFormulaMetabolicStressFactor = this.draft?.nutritionalConditions?.perkeniFormula?.metabolicStressFactor ? this.draft?.nutritionalConditions?.perkeniFormula?.metabolicStressFactor : null
        this.form.perkeniFormulaMalnutritionStatusFactor = this.draft?.nutritionalConditions?.perkeniFormula?.malnutritionStatusFactor ? this.draft?.nutritionalConditions?.perkeniFormula?.malnutritionStatusFactor : null
        this.form.perkeniFormulaOvernutritionStatusFactor = this.draft?.nutritionalConditions?.perkeniFormula?.overnutritionStatusFactor ? this.draft?.nutritionalConditions?.perkeniFormula?.overnutritionStatusFactor : null
        this.form.perkeniFormulaMaternalCondition = this.draft?.nutritionalConditions?.perkeniFormula?.maternalCondition ? this.draft?.nutritionalConditions?.perkeniFormula?.maternalCondition : null
        this.form.perkeniFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.perkeniFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.perkeniFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'ckdFormula') {
        this.form.ckdFormulaWeightChoice = this.draft?.nutritionalConditions?.ckdFormula?.weightChoice ? this.draft?.nutritionalConditions?.ckdFormula?.weightChoice : null
        this.form.ckdFormulaCkdEnergyRequirement = this.draft?.nutritionalConditions?.ckdFormula?.ckdEnergyRequirement ? this.draft?.nutritionalConditions?.ckdFormula?.ckdEnergyRequirement : null
        this.form.ckdFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.ckdFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.ckdFormula?.totalEnergyNeeded : null
      } else if (this.draft?.nutritionalConditions?.formula === 'manualFormula') {
        this.form.manualFormulaTotalEnergyNeeded = this.draft?.nutritionalConditions?.manualFormula?.totalEnergyNeeded ? this.draft?.nutritionalConditions?.manualFormula?.totalEnergyNeeded : null
      }
    }
    if (String(this.form.height) !== String(this.basicData?.height)) {
      this.isEditHeight = true
    }
    if (String(this.form.weight) !== String(this.basicData?.weight)) {
      this.isEditWeight = true
    }

    this.formDuplicate = _.cloneDeep(this.form)
    setTimeout(() => {
      this.isLoadingData = false
    }, 1500)
  },
  methods: {
    ...mapMutations({
      setSaveState: 'general/setSaveState',
      setMealPlanPreview: 'mealPlan/setMealPlanPreview',
    }),
    isAllFormValid,
    isInvalidField,
    parseErrors,
    onClickBackToPreview() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalBackToPreview = true
        return
      }
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    handleBackToPreview() {
      this.isOpenModalBackToPreview = false
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    async getBasicData() {
      try {
        const res = await reqNutriMealPlan.getBasicData(this.$store.getters.axios, { 
          clientId: this.$route.params.clientId, 
        })
        this.basicData = res.data.data
        if (!this.draft?.nutritionalConditions) {
          this.form.name = this.basicData?.name
          this.form.age = this.basicData?.age
          this.form.birthDate = this.basicData?.birthDate
          this.form.gender = this.basicData?.gender
          this.form.weight = this.basicData?.weight
          this.form.height = this.basicData?.height
        }
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      }
    },
    onOpenModalCancel() {
      if (!this.isLoadingSave) {
        this.isOpenModalCancel = true
      }
    },
    onCloseModalCancel() {
      this.isOpenModalCancel = false
    },
    onConfirmModalCancel() {
      this.onCloseModalCancel()
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    onEditWeight() {
      this.isEditWeight = true
    },
    onEditHeight() {
      this.isEditHeight = true
    },
    handleChangeStep(tab, savePosition) {
      this.$v.$touch()
      this.stepTo.tab = tab
      this.stepTo.savePosition = savePosition
      if (this.$v.$invalid) {
        this.isOpenModalNext = true
        return
      }
      this.onStepTo()
    },
    onCancelNext() {
      this.isOpenModalNext = false
    },
    async onStepTo() {
      try {
        await this.onSaveDraft()
        this.$router.replace({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            ...this.$route.params,
            savePosition: this.stepTo.savePosition,
          },
          query: {
            tab: this.stepTo.tab,
          },
        })
      } catch (e) {
        //
      }
    },
    async onSave() {
      try {
        await this.onSaveDraft(true)
      } catch (err) {
        this.isOpenModalFailedSaveData = true
      }
    },
    async onSaveDraft(confirm = null) {
      try {
        this.isLoadingSave = true
        this.onFormTouch()
        this.setSaveState(null)

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: Number(this.$route.params.month),
          nutritionalConditions: {
            name: this.form.name,
            age: this.form.age,
            birthDate: this.form.birthDate,
            gender: this.form.gender,
            weight: this.form.weight,
            height: this.form.height,
            calculationType: this.form.calculationType,
            validation: this.$v.form.$invalid ? -1 : 1,
          },
        }

        if (this.form.calculationType === 'adult') {
          payload = {
            ...payload,
            nutritionalConditions: {
              ...payload.nutritionalConditions,
              imt: this.form.imt,
              nutritionalStatus: this.form.nutritionalStatus,
              minIdealWeight: this.form.minIdealWeight,
              maxIdealWeight: this.form.maxIdealWeight,
              medianIdealWeight: this.form.medianIdealWeight,
              broccaIdealWeight: this.form.broccaIdealWeight,
              adjustedIdealWeight: this.form.adjustedIdealWeight,
              formula: this.form.formula,
            },
          }

          if (this.form.formula) {
            if (this.form.formula === 'mifflinStJeorFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  mifflinStJeorFormula: {
                    weightChoice: this.form.mifflinStJeorFormulaWeightChoice,
                    weightValue: this.form[this.form.mifflinStJeorFormulaWeightChoice],
                    bassEnergyRequirement: this.form.mifflinStJeorFormulaBassEnergyRequirement,
                    physicalActivity: this.form.mifflinStJeorFormulaPhysicalActivity,
                    maternalCondition: this.form.mifflinStJeorFormulaMaternalCondition,
                    calorieChoice: this.form.mifflinStJeorFormulaCalorieChoice,
                    calorieDeficit: this.form.mifflinStJeorFormulaCalorieChoice === 'surplus' ? `${-Number(this.form.mifflinStJeorFormulaCalorieDeficit)}` : this.form.mifflinStJeorFormulaCalorieDeficit,
                    totalEnergyNeeded: this.form.mifflinStJeorFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else if (this.form.formula === 'perkeniFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  perkeniFormula: {
                    weightChoice: this.form.perkeniFormulaWeightChoice,
                    weightValue: this.form[this.form.perkeniFormulaWeightChoice],
                    bassEnergyRequirement: this.form.perkeniFormulaBassEnergyRequirement,
                    physicalActivity: this.form.perkeniFormulaPhysicalActivity,
                    ageFactor: this.form.perkeniFormulaAgeFactor,
                    metabolicStressFactor: this.form.perkeniFormulaMetabolicStressFactor,
                    malnutritionStatusFactor: this.form.perkeniFormulaMalnutritionStatusFactor,
                    overnutritionStatusFactor: this.form.perkeniFormulaOvernutritionStatusFactor,
                    maternalCondition: this.form.perkeniFormulaMaternalCondition,
                    totalEnergyNeeded: this.form.perkeniFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else if (this.form.formula === 'ckdFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  ckdFormula: {
                    weightChoice: this.form.ckdFormulaWeightChoice,
                    weightValue: this.form[this.form.ckdFormulaWeightChoice],
                    ckdEnergyRequirement: this.form.ckdFormulaCkdEnergyRequirement,
                    totalEnergyNeeded: this.form.ckdFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  manualFormula: {
                    weightValue: this.form.weight,
                    totalEnergyNeeded: this.form.manualFormulaTotalEnergyNeeded,
                  },
                },
              }
            }
          }
        } else {
          payload = {
            ...payload,
            nutritionalConditions: {
              ...payload.nutritionalConditions,
              correctedHeight: this.form.correctedHeight,
              imt: this.form.imt,
              childAgeRange: this.form.childAgeRange,
              childIdealWeight: this.form.childIdealWeight,
              childIdealHeight: this.form.childIdealHeight,
              ageAccordingHeight: this.form.ageAccordingHeight,
              formula: this.form.formula,
            },
          }
          
          if (this.form.childAgeRange) {
            if (this.form.childAgeRange === '0 - 60 bulan') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  childAgeRangeMonth: {
                    nutritionalStatusWeightPerAge: this.form.childAgeRangeMonthNutritionalStatusWeightPerAge,
                    nutritionalStatusHeightPerAge: this.form.childAgeRangeMonthNutritionalStatusHeightPerAge,
                    nutritionalStatusHeightPerWeight: this.form.childAgeRangeMonthNutritionalStatusHeightPerWeight,
                    nutritionalStatusImtPerAge: this.form.childAgeRangeMonthNutritionalStatusImtPerAge,
                    nutritionalStatusConclusionChoice: this.form.childAgeRangeMonthNutritionalStatusConclusionChoice,
                    nutritionalStatusConclusion: this.form.childAgeRangeMonthNutritionalStatusConclusion,
                    zScoreWeightPerAge: this.form.zScoreWeightPerAge,
                    zScoreHeightPerAge: this.form.zScoreHeightPerAge,
                    zScoreHeightPerWeight: this.form.zScoreHeightPerWeight,
                    zScoreImtPerAge: this.form.zScoreImtPerAge,
                    zScoreConclusion: this.form.zScoreConclusion,
                  },
                },
              }
            } else {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  childAgeRangeYear: {
                    nutritionalStatusImtPerAge: this.form.childAgeRangeYearNutritionalStatusImtPerAge,
                    zScoreImtPerAge: this.form.zScoreImtPerAge,
                    nutritionalStatusConclusion: this.form.childAgeRangeYearNutritionalStatusImtPerAge,
                    zScoreConclusion: this.form.zScoreImtPerAge,
                  },
                },
              }
            }
          }

          if (this.form.formula) {
            if (this.form.formula === 'monthFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  monthFormula: {
                    calculationAccordingAgeAndHeight: this.form.monthFormulaCalculationAccordingAgeAndHeight,
                    totalEnergyNeeded: this.form.monthFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else if (this.form.formula === 'yearMaleFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  yearMaleFormula: {
                    calculationAccordingAgeAndHeight: this.form.yearMaleFormulaCalculationAccordingAgeAndHeight,
                    totalEnergyNeeded: this.form.yearFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else if (this.form.formula === 'yearFemaleFormula') {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  yearFemaleFormula: {
                    calculationAccordingAgeAndHeight: this.form.yearFemaleFormulaCalculationAccordingAgeAndHeight,
                    totalEnergyNeeded: this.form.yearFormulaTotalEnergyNeeded,
                  },
                },
              }
            } else {
              payload = {
                ...payload,
                nutritionalConditions: {
                  ...payload.nutritionalConditions,
                  manualFormula: {
                    totalEnergyNeeded: this.form.manualFormulaTotalEnergyNeeded,
                  },
                },
              }
            }
          }
        }

        const res = await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })
        this.setMealPlanPreview(res?.data?.data)

        if (!isAllFormValid({
          nutritionalConditions: !this.$v.$invalid,
          nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
          bodyConditions: this.draft?.bodyConditions?.validation === 1,
          targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
        })) {
          EventBus.$emit('TRIGGER_SAVE_MEAL_PLAN', Date.now())
        }
        this.setSaveState(true)
        if (confirm) {
          this.isOpenModalSuccessSaveData = true
        }
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
    updateNutritionalStatusConclusion() {
      switch(this.form.childAgeRangeMonthNutritionalStatusConclusionChoice) {
        case 'nutritionalStatusHeightPerWeight':
          this.form.childAgeRangeMonthNutritionalStatusConclusion = this.form.childAgeRangeMonthNutritionalStatusHeightPerWeight ?? null 
          this.form.zScoreConclusion = this.form.zScoreHeightPerWeight ?? null
          break
        case 'nutritionalStatusImtPerAge':
          this.form.childAgeRangeMonthNutritionalStatusConclusion = this.form.childAgeRangeMonthNutritionalStatusImtPerAge ?? null 
          this.form.zScoreConclusion = this.form.zScoreImtPerAge ?? null
          break
        default:
          this.form.childAgeRangeMonthNutritionalStatusConclusion = null 
          this.form.zScoreConclusion = null
          break
      }
    },
    updateMonthFormulaTotalEnergyNeeded() {
      if (this.form.monthFormulaCalculationAccordingAgeAndHeight) {
        let weight = parseFloat(this.form.childIdealWeight ?? 0) 

        this.form.monthFormulaTotalEnergyNeeded = totalEnergyChild.totalEnergyNeeded(this.form.monthFormulaCalculationAccordingAgeAndHeight, weight)
      } else {
        this.form.monthFormulaTotalEnergyNeeded = null
      }
    },
    updateYearFormulaTotalEnergyNeeded() {
      if (this.form.yearMaleFormulaCalculationAccordingAgeAndHeight || this.form.yearFemaleFormulaCalculationAccordingAgeAndHeight) {
        let weight = parseFloat(this.form.childIdealWeight ?? 0) 

        if (this.form.formula === 'yearMaleFormula') {
          this.form.yearFormulaTotalEnergyNeeded = totalEnergyChild.totalEnergyNeeded(this.form.yearMaleFormulaCalculationAccordingAgeAndHeight, weight)
        } else if (this.form.formula === 'yearFemaleFormula') {
          this.form.yearFormulaTotalEnergyNeeded = totalEnergyChild.totalEnergyNeeded(this.form.yearFemaleFormulaCalculationAccordingAgeAndHeight, weight)
        }
      } else {
        this.form.yearFormulaTotalEnergyNeeded
      }
    },
    updateNutritionalStatusChild() {
      let weight = parseFloat(this.form.weight ?? 0)

      this.form.imt = nutritionalStatusChild.calculateImt(weight, this.form.correctedHeight)
    },
    async updateNutritionalStatusAdult() {
      let weight = parseFloat(this.form.weight ?? 0)
      let height = parseFloat(this.form.height ?? 0)
      const imt = await nutritionalStatusAdult.calculateImt(weight, height)

      this.form.imt = imt
      this.form.nutritionalStatus = nutritionalStatusAdult.getBmiStatus(imt)
      this.form.minIdealWeight = nutritionalStatusAdult.getIdealWeightMinimum(height)
      this.form.maxIdealWeight = nutritionalStatusAdult.getIdealWeightMaximum(height)
      this.form.medianIdealWeight = nutritionalStatusAdult.getIdealWeightMedian(height)
      this.form.broccaIdealWeight = nutritionalStatusAdult.getBroccaIdealWeight(height, this.form.gender)
      this.form.adjustedIdealWeight = nutritionalStatusAdult.getIdealWeightAdjusted(weight, height, this.form.gender)
    },
    getFormulaWeight(choice) {
      switch (choice) {
        case 'weight':
          return this.form.weight
        case 'minIdealWeight':
          return this.form.minIdealWeight
        case 'maxIdealWeight':
          return this.form.maxIdealWeight
        case 'medianIdealWeight':
          return this.form.medianIdealWeight
        case 'broccaIdealWeight':
          return this.form.broccaIdealWeight
        case 'adjustedIdealWeight':
          return this.form.adjustedIdealWeight
        default:
          return 0
      }
    },
    updateMifflinStJeorFormulaBassEnergyRequirement() {
      if (this.form.mifflinStJeorFormulaWeightChoice) {
        let weight = this.getFormulaWeight(this.form.mifflinStJeorFormulaWeightChoice)
        let height = parseFloat(this.form.height ?? 0)

        this.form.mifflinStJeorFormulaBassEnergyRequirement = mifflinStJeorFormula.bassEnergyRequirement(this.form.age, this.form.gender, weight, height)
      }
    },
    updateMifflinStJeorFormulaTotalEnergyNeeded() {
      let weight = this.getFormulaWeight(this.form.mifflinStJeorFormulaWeightChoice)
      let height = parseFloat(this.form.height ?? 0)
      let mifflinStJeorFormulaPhysicalActivity = Number(this.form.mifflinStJeorFormulaPhysicalActivity ?? 0)
      let mifflinStJeorFormulaMaternalCondition = Number(this.form.mifflinStJeorFormulaMaternalCondition ?? 0)
      let mifflinStJeorFormulaCalorieDeficit = Number(this.form.mifflinStJeorFormulaCalorieDeficit ?? 0)

      // if suplus, then make it to negative
      if (this.form.mifflinStJeorFormulaCalorieChoice === 'surplus') {
        mifflinStJeorFormulaCalorieDeficit = -mifflinStJeorFormulaCalorieDeficit
      }

      this.form.mifflinStJeorFormulaTotalEnergyNeeded = mifflinStJeorFormula.totalEnergyNeeded(
        this.form.age,
        this.form.gender,
        weight,
        height,
        mifflinStJeorFormulaPhysicalActivity,
        mifflinStJeorFormulaMaternalCondition,
        mifflinStJeorFormulaCalorieDeficit,
      )
    },
    updatePerkeniFormulaBassEnergyRequirement() {
      if (this.form.perkeniFormulaWeightChoice) {
        let weight = this.getFormulaWeight(this.form.perkeniFormulaWeightChoice)

        this.form.perkeniFormulaBassEnergyRequirement = perkeniFormula.bassEnergyRequirement(this.form.gender, weight)
      }
    },
    updatePerkeniFormulaTotalEnergyNeeded() {
      let weight = this.getFormulaWeight(this.form.perkeniFormulaWeightChoice)
      let perkeniFormulaPhysicalActivity = Number(this.form.perkeniFormulaPhysicalActivity ?? 0)
      let perkeniFormulaAgeFactor = Number(this.form.perkeniFormulaAgeFactor ?? 0)
      let perkeniFormulaMetabolicStressFactor = Number(this.form.perkeniFormulaMetabolicStressFactor ?? 0)
      let perkeniFormulaMalnutritionStatusFactor = Number(this.form.perkeniFormulaMalnutritionStatusFactor ?? 0)
      let perkeniFormulaOvernutritionStatusFactor = Number(this.form.perkeniFormulaOvernutritionStatusFactor ?? 0)
      let perkeniFormulaMaternalCondition = Number(this.form.perkeniFormulaMaternalCondition ?? 0)

      this.form.perkeniFormulaTotalEnergyNeeded = perkeniFormula.totalEnergyNeeded(
        this.form.gender,
        weight,
        perkeniFormulaPhysicalActivity,
        perkeniFormulaAgeFactor,
        perkeniFormulaMetabolicStressFactor,
        perkeniFormulaMalnutritionStatusFactor,
        perkeniFormulaOvernutritionStatusFactor,
        perkeniFormulaMaternalCondition,
      )
    },
    updateCkdFormulaTotalEnergyNeeded() {
      let weight = this.getFormulaWeight(this.form.ckdFormulaWeightChoice)
      let ckdFormulaCkdEnergyRequirement = Number(this.form.ckdFormulaCkdEnergyRequirement ?? 0)

      this.form.ckdFormulaTotalEnergyNeeded = ckdFormula.totalEnergyNeeded(
        weight,
        ckdFormulaCkdEnergyRequirement,
      )
    },
    onChangeCalculationType() {
      this.form.formula = null
      if (this.form.calculationType === 'adult') {
        this.onResetFormChildAgeRange()
        this.onResetFormChild()
        this.onResetFormFormulaChild()
      } else {
        this.onResetFormFormulaAdult()
      }
    },
    onResetFormFormulaAdult() {
      this.form.mifflinStJeorFormulaWeightChoice = null
      this.form.mifflinStJeorFormulaBassEnergyRequirement = null
      this.form.mifflinStJeorFormulaPhysicalActivity = null
      this.form.mifflinStJeorFormulaMaternalCondition = null
      this.form.mifflinStJeorFormulaCalorieChoice = null
      this.form.mifflinStJeorFormulaCalorieDeficit = null
      this.form.mifflinStJeorFormulaTotalEnergyNeeded = null
      this.form.perkeniFormulaWeightChoice = null
      this.form.perkeniFormulaBassEnergyRequirement = null
      this.form.perkeniFormulaPhysicalActivity = null
      this.form.perkeniFormulaAgeFactor = null
      this.form.perkeniFormulaMetabolicStressFactor = null
      this.form.perkeniFormulaMalnutritionStatusFactor = null
      this.form.perkeniFormulaOvernutritionStatusFactor = null
      this.form.perkeniFormulaMaternalCondition = null
      this.form.perkeniFormulaTotalEnergyNeeded = null
      this.form.ckdFormulaWeightChoice = null
      this.form.ckdFormulaCkdEnergyRequirement = null
      this.form.ckdFormulaTotalEnergyNeeded = null
      this.form.manualFormulaTotalEnergyNeeded = null
    },
    
    onResetFormChildAgeRange() {
      this.form.childAgeRangeMonthNutritionalStatusWeightPerAge = null
      this.form.childAgeRangeMonthNutritionalStatusHeightPerAge = null
      this.form.childAgeRangeMonthNutritionalStatusHeightPerWeight = null
      this.form.childAgeRangeMonthNutritionalStatusImtPerAge = null
      this.form.childAgeRangeMonthNutritionalStatusConclusionChoice = null
      this.form.childAgeRangeMonthNutritionalStatusConclusion = null
      this.form.childAgeRangeYearNutritionalStatusImtPerAge = null
      this.form.zScoreWeightPerAge = null
      this.form.zScoreHeightPerAge = null
      this.form.zScoreHeightPerWeight = null
      this.form.zScoreImtPerAge = null
      this.form.zScoreConclusion = null
    },

    onResetFormChild() {
      this.form.correctedHeight = null
      this.form.childAgeRange = null
      this.form.childIdealWeight = null
      this.form.childIdealHeight = null
      this.form.ageAccordingHeight = null
    },

    onResetFormFormulaChild() {
      this.form.monthFormulaCalculationAccordingAgeAndHeight = null
      this.form.monthFormulaTotalEnergyNeeded = null
      this.form.yearMaleFormulaCalculationAccordingAgeAndHeight = null
      this.form.yearFemaleFormulaCalculationAccordingAgeAndHeight = null
      this.form.yearFormulaTotalEnergyNeeded = null
      this.form.manualFormulaTotalEnergyNeeded = null
    },

    onFormTouch() {
      this.$v.form.calculationType.$touch()

      if (this.form.calculationType === 'child') {
        this.$v.form.correctedHeight.$touch()
        this.$v.form.childAgeRange.$touch()

        if (this.form.childAgeRange === '0 - 60 bulan') {
          this.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge.$touch()
          this.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge.$touch()
          this.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight.$touch()
          this.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge.$touch()
          this.$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice.$touch()
        } else if (this.form.childAgeRange === '> 5 - 18 tahun') {
          this.$v.form.childAgeRangeYearNutritionalStatusImtPerAge.$touch()
        }
        
        this.$v.form.childIdealWeight.$touch()
        this.$v.form.childIdealHeight.$touch()
        this.$v.form.ageAccordingHeight.$touch()
        this.$v.form.childAgeRange.$touch()
        this.$v.form.formula.$touch()

        if (this.form.formula === 'manualFormula') {
          this.$v.form.manualFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'monthFormula') {
          this.$v.form.monthFormulaCalculationAccordingAgeAndHeight.$touch()
          this.$v.form.monthFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'yearMaleFormula') {
          this.$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight.$touch()
          this.$v.form.yearFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'yearFemaleFormula') {
          this.$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight.$touch()
          this.$v.form.yearFormulaTotalEnergyNeeded.$touch()
        }

      } else if (this.form.calculationType === 'adult') {
        this.$v.form.formula.$touch()

        if (this.form.formula === 'manualFormula') {
          this.$v.form.manualFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'mifflinStJeorFormula') {
          this.$v.form.mifflinStJeorFormulaWeightChoice.$touch()
          this.$v.form.mifflinStJeorFormulaPhysicalActivity.$touch()
          this.$v.form.mifflinStJeorFormulaMaternalCondition.$touch()
          this.$v.form.mifflinStJeorFormulaCalorieChoice.$touch()
          this.$v.form.mifflinStJeorFormulaCalorieDeficit.$touch()
          this.$v.form.mifflinStJeorFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'perkeniFormula') {
          this.$v.form.perkeniFormulaWeightChoice.$touch()
          this.$v.form.perkeniFormulaPhysicalActivity.$touch()
          this.$v.form.perkeniFormulaAgeFactor.$touch()
          this.$v.form.perkeniFormulaMetabolicStressFactor.$touch()
          this.$v.form.perkeniFormulaMalnutritionStatusFactor.$touch()
          this.$v.form.perkeniFormulaOvernutritionStatusFactor.$touch()
          this.$v.form.perkeniFormulaMaternalCondition.$touch()
          this.$v.form.perkeniFormulaTotalEnergyNeeded.$touch()
        } else if (this.form.formula === 'ckdFormula') {
          this.$v.form.ckdFormulaWeightChoice.$touch()
          this.$v.form.ckdFormulaCkdEnergyRequirement.$touch()
          this.$v.form.ckdFormulaTotalEnergyNeeded.$touch()
        }
      }
    },
  },
}
</script>
