<template>
  <c-accordion-item
    border="none"
    :is-open="isOpen"
    @change="toggleAccordion"
  >
    <c-accordion-header
      v-chakra="{
        _hover: {
          background: isError ? '#f9cebf' : '#9be8c7',
        },
      }"
      rounded="8px"
      :background-color="isError ? '#FCDED3' : '#C7F9E3'"
      :h="['48px', '62px']"
      :border="withBorderHeader ? '1px solid #888' : 'none'"
      :box-shadow="withBorderHeader ? '0 0 0 1px #888': 'none'"
    >
      <c-box
        flex="1"
        text-align="left"
        :color="isError ? '#D32737' : '#008C81'"
        font-family="Roboto, sans-serif"
        :font-weight="[500, 600]"
        :font-size="['14px', '18px']"
      >
        {{ label }}
      </c-box>
      <c-accordion-icon
        size="24px"
        name="chevron-down"
      />
    </c-accordion-header>
    <c-accordion-panel pb="2">
      <slot />
    </c-accordion-panel>
  </c-accordion-item>
</template>

<script>
export default {
  name: 'FormAccordionListItem',
  inject: ['accordion', 'setIsOpenAccordionItem', 'registerAccordionItemId'],
  props: {
    label: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
    },
    defaultIsOpen: {
      type: Boolean,
      default: false,
    },
    withBorderHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOpen() {
      // return false
      return this.accordion?.accordion_?.[this._uid]
    },
  },
  created() {
    this.registerAccordionItemId(this._uid, true)
    if (this.defaultIsOpen) {
      this.toggleAccordion(true)
    }
  },
  methods: {
    toggleAccordion(value) {
      this.setIsOpenAccordionItem(this._uid, value)
    },
  },
}
</script>

<style scoped>
::v-deep [data-chakra-component="CCollapse"] {
  position: relative;
}
</style>