<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['12px', '16px']"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="isDisabled ? '#888' : (isInvalid ? '#D32737' : (value ? '#008C81' : '#555'))"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>
    <c-stack
      margin-top="4px"
      margin-left="8px"
    >
      <c-radio-group
        v-model="modelValue"
        variant-color="primary"
        :is-disabled="isDisabled"
        size="lg"
        :font-size="['14px', '16px']"
        :spacing="spacing"
        :is-inline="isInline"
      >
        <c-radio 
          v-for="(option) in options"
          :key="option.value"
          :value="option.value?.toString()"
          :is-disabled="isDisabled"
          :color="modelValue == option.value ? '#008C81' : '#555'"
          :font-weight="modelValue == option.value ? '500' : '400'"
        >
          {{ option.label }}
        </c-radio>
      </c-radio-group>
    </c-stack>
    <c-form-helper-text
      v-if="isHelper"
      font-size="12px"
      margin-left="10px"
    >
      {{ helperText }}
    </c-form-helper-text>
    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
      margin-left="10px"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'FormRadio',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null, Number],
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: String,
      default: '2',
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value || null
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
  }, 
  methods: {
    isDataEmpty,
  }, 
}
</script>

<style scoped>
::v-deep [data-chakra-component="CRadio"]  {
  height: 40px;
}
::v-deep [data-chakra-component="CRadio"] [data-chakra-component="CBox"] {
  font-size: 16px;
}
::v-deep [data-chakra-component="CRadio"] [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #333333;
}
::v-deep [data-chakra-component="CRadio"] input[type=radio]:checked + [data-chakra-component="CControlBox"] {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #008C81;
  background: white;
}
::v-deep [data-chakra-component="CRadio"] input[type=radio]:checked + [data-chakra-component="CControlBox"] span {
  background: #008C81;
}
</style>