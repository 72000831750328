var render = function () {
  var _vm$$v, _vm$constants$nutriti, _vm$constants$nutriti2, _vm$constants$nutriti3, _vm$constants$nutriti4, _vm$constants$nutriti5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-box', {
    attrs: {
      "position": "sticky",
      "top": "calc(84px)",
      "background-color": "white",
      "z-index": "10",
      "box-shadow": "0px 16px 0px white, 10px 16px 0px white, -10px 16px 0px white, 10px -16px 0px white, -10px -16px 0px white"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": _vm.onClickBackToPreview
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Asupan Gizi ")]), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.iconCloud.state === 'loading' ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllFormProfilGizi || _vm.saveState === null || _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _vm.isFormDisabled ? _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "background-color": "danger.50",
      "padding": "6px 10px",
      "border-radius": "8px",
      "align-items": "center",
      "gap": "10px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-circle-warn.svg'),
      "height": "20px",
      "width": "20px",
      "fill": "#D32737"
    }
  }), _c('c-text', {
    attrs: {
      "color": "danger.400",
      "font-size": "14px"
    }
  }, [_vm._v(" Selesaikan formulir Kondisi Giziku terlebih dahulu untuk mengaktifkan formulir ini ")])], 1) : _vm._e(), _c('NutritionalProfileSteps', {
    attrs: {
      "draft": _vm.draft,
      "nutritional-intake": !((_vm$$v = _vm.$v) !== null && _vm$$v !== void 0 && _vm$$v.$invalid) ? 1 : -1,
      "is-loading": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "change:step": function changeStep(step) {
        return _vm.handleChangeStep(step, true);
      }
    }
  })], 1), _c('c-box', {
    attrs: {
      "padding-top": "16px"
    }
  }, [_c('c-box', [_c('c-grid', {
    attrs: {
      "template-columns": "auto auto 240px 240px",
      "gap": "16px"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Asupan Energi Sekarang (kkal)",
      "placeholder": "Masukkan asupan energi sekarang",
      "is-required": "",
      "right-element": "kkal",
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.energyIntakeCurrent),
      "invalid-text": _vm.parseErrors('Asupan energi sekarang', _vm.$v.form.energyIntakeCurrent, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.energyIntakeCurrent.$touch
    },
    model: {
      value: _vm.form.energyIntakeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "energyIntakeCurrent", $$v);
      },
      expression: "form.energyIntakeCurrent"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Catatan Asupan Energi",
      "placeholder": "Masukkan catatan energi sekarang",
      "full-width": "",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": _vm.isInvalidField(_vm.$v.form.energyIntakeNote) ? '' : 'Maksimal 300 karakter',
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.energyIntakeNote),
      "invalid-text": _vm.parseErrors('Catatan asupan energi', _vm.$v.form.energyIntakeNote)
    },
    on: {
      "blur": _vm.$v.form.energyIntakeNote.$touch
    },
    model: {
      value: _vm.form.energyIntakeNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "energyIntakeNote", $$v);
      },
      expression: "form.energyIntakeNote"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "% Kecukupan Energi",
      "value": "".concat(_vm.calculation.energySufficiency, "%") || '-',
      "color": _vm.calculation.energySufficiency ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Status Energi",
      "value": _vm.calculation.energyStatus || '-',
      "color": (_vm$constants$nutriti = _vm.constants.nutritionalIntakeColorStatus.find(function (v) {
        return v.value === _vm.calculation.energyStatus;
      })) === null || _vm$constants$nutriti === void 0 ? void 0 : _vm$constants$nutriti.color,
      "width": "240px"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "36px",
      "border-color": "neutral.lightGray"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": "auto auto 240px 240px",
      "gap": "16px"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Asupan Protein Sekarang (gram)",
      "placeholder": "Masukkan asupan protein sekarang",
      "is-required": "",
      "right-element": "g",
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.proteinIntakeCurrent),
      "invalid-text": _vm.parseErrors('Asupan protein sekarang', _vm.$v.form.proteinIntakeCurrent, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.proteinIntakeCurrent.$touch
    },
    model: {
      value: _vm.form.proteinIntakeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "proteinIntakeCurrent", $$v);
      },
      expression: "form.proteinIntakeCurrent"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-required": "",
      "label": "Kebutuhan Protein per kg Berat Badan",
      "placeholder": "Masukkan kebutuhan protein per kg bb",
      "options": _vm.constants.proteinRequirementsperkgBodyWeightOptions,
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.proteinRequirementsperkgBodyWeight),
      "invalid-text": _vm.parseErrors('Kebutuhan protein per kg bb', _vm.$v.form.proteinRequirementsperkgBodyWeight)
    },
    on: {
      "blur": _vm.$v.form.proteinRequirementsperkgBodyWeight.$touch
    },
    model: {
      value: _vm.form.proteinRequirementsperkgBodyWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "proteinRequirementsperkgBodyWeight", $$v);
      },
      expression: "form.proteinRequirementsperkgBodyWeight"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Kebutuhan Asupan Protein",
      "value": _vm.calculation.proteinRequirement || '-',
      "color": _vm.calculation.proteinRequirement ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Konstanta Kebutuhan Protein",
      "value": _vm.calculation.proteinRequirementConstant || '-',
      "color": _vm.calculation.proteinRequirementConstant ? 'green' : '',
      "width": "240px"
    }
  }), _c('c-box', {
    attrs: {
      "grid-column": "span 2"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Catatan Asupan Protein",
      "placeholder": "Masukkan catatan Asupan Protein",
      "full-width": "",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": _vm.isInvalidField(_vm.$v.form.proteinIntakeNote) ? '' : 'Maksimal 300 karakter',
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.proteinIntakeNote),
      "invalid-text": _vm.parseErrors('Catatan asupan protein', _vm.$v.form.proteinIntakeNote)
    },
    on: {
      "blur": _vm.$v.form.proteinIntakeNote.$touch
    },
    model: {
      value: _vm.form.proteinIntakeNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "proteinIntakeNote", $$v);
      },
      expression: "form.proteinIntakeNote"
    }
  })], 1), _c('StatusLabel', {
    attrs: {
      "label": "% Kecukupan Protein",
      "value": _vm.calculation.proteinAdequacy ? "".concat(_vm.calculation.proteinAdequacy, "%") : '-',
      "color": _vm.calculation.proteinAdequacy ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Status Protein",
      "value": _vm.calculation.proteinStatus,
      "color": (_vm$constants$nutriti2 = _vm.constants.nutritionalIntakeColorStatus.find(function (v) {
        return v.value === _vm.calculation.proteinStatus;
      })) === null || _vm$constants$nutriti2 === void 0 ? void 0 : _vm$constants$nutriti2.color,
      "width": "240px"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "36px",
      "border-color": "neutral.lightGray"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": "auto auto 240px 240px",
      "gap": "16px"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Asupan Lemak Sekarang (gram)",
      "placeholder": "Masukkan asupan lemak sekarang",
      "is-required": "",
      "right-element": "g",
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fatIntakeCurrent),
      "invalid-text": _vm.parseErrors('Asupan lemak sekarang', _vm.$v.form.fatIntakeCurrent, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.fatIntakeCurrent.$touch
    },
    model: {
      value: _vm.form.fatIntakeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fatIntakeCurrent", $$v);
      },
      expression: "form.fatIntakeCurrent"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-required": "",
      "label": "% Kebutuhan Lemak",
      "placeholder": "Masukkan % kebutuhan lemak ",
      "options": _vm.constants.fatRequirementsOptions,
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fatRequirements),
      "invalid-text": _vm.parseErrors('% kebutuhan lemak', _vm.$v.form.fatRequirements)
    },
    on: {
      "blur": _vm.$v.form.fatRequirements.$touch
    },
    model: {
      value: _vm.form.fatRequirements,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fatRequirements", $$v);
      },
      expression: "form.fatRequirements"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "% Kecukupan Lemak",
      "value": "".concat(_vm.calculation.fatAdequacy, "%") || '-',
      "color": _vm.calculation.fatAdequacy ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Kebutuhan Asupan Lemak",
      "value": _vm.calculation.fatRequirement || '-',
      "color": _vm.calculation.fatRequirement ? 'green' : 'red',
      "width": "240px"
    }
  }), _c('c-box', {
    attrs: {
      "grid-column": "span 2"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Catatan Asupan Lemak",
      "placeholder": "Masukkan catatan asupan lemak",
      "full-width": "",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": _vm.isInvalidField(_vm.$v.form.fatIntakenote) ? '' : 'Maksimal 300 karakter',
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fatIntakenote),
      "invalid-text": _vm.parseErrors('Catatan asupan lemak', _vm.$v.form.fatIntakenote)
    },
    on: {
      "blur": _vm.$v.form.fatIntakenote.$touch
    },
    model: {
      value: _vm.form.fatIntakenote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fatIntakenote", $$v);
      },
      expression: "form.fatIntakenote"
    }
  })], 1), _c('StatusLabel', {
    attrs: {
      "label": "Status Lemak",
      "value": _vm.calculation.fatStatus || '-',
      "color": (_vm$constants$nutriti3 = _vm.constants.nutritionalIntakeColorStatus.find(function (v) {
        return v.value === _vm.calculation.fatStatus;
      })) === null || _vm$constants$nutriti3 === void 0 ? void 0 : _vm$constants$nutriti3.color,
      "width": "240px"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "36px",
      "border-color": "neutral.lightGray"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": "auto auto 240px 240px",
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "grid-column": "span 2"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Asupan Karbohidrat Sekarang (gram)",
      "placeholder": "Masukkan asupan karbohidrat sekarang",
      "is-required": "",
      "right-element": "g",
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.carboIntakeCurrent),
      "invalid-text": _vm.parseErrors('Asupan karbohidrat sekarang', _vm.$v.form.carboIntakeCurrent, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.carboIntakeCurrent.$touch
    },
    model: {
      value: _vm.form.carboIntakeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "carboIntakeCurrent", $$v);
      },
      expression: "form.carboIntakeCurrent"
    }
  })], 1), _c('StatusLabel', {
    attrs: {
      "label": "Kebutuhan Asupan Karbohidrat",
      "value": _vm.calculation.carbohydrateRequirement || '-',
      "color": _vm.calculation.carbohydrateRequirement ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "% Kecukupan Karbohidrat",
      "value": _vm.calculation.carbohydrateAdequacy ? "".concat(_vm.calculation.carbohydrateAdequacy, "%") : '-',
      "color": _vm.calculation.carbohydrateAdequacy ? 'green' : '',
      "width": "240px"
    }
  }), _c('c-box', {
    attrs: {
      "grid-column": "span 2"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Catatan Asupan Karbohidrat",
      "placeholder": "Masukkan catatan asupan karbohidrat",
      "full-width": "",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": _vm.isInvalidField(_vm.$v.form.carboIntakeNote) ? '' : 'Maksimal 300 karakter',
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.carboIntakeNote),
      "invalid-text": _vm.parseErrors('Catatan asupan karbohidrat', _vm.$v.form.carboIntakeNote)
    },
    on: {
      "blur": _vm.$v.form.carboIntakeNote.$touch
    },
    model: {
      value: _vm.form.carboIntakeNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "carboIntakeNote", $$v);
      },
      expression: "form.carboIntakeNote"
    }
  })], 1), _c('StatusLabel', {
    attrs: {
      "label": "Status Karbohidrat",
      "value": _vm.calculation.carbohydrateStatus || '-',
      "color": (_vm$constants$nutriti4 = _vm.constants.nutritionalIntakeColorStatus.find(function (v) {
        return v.value === _vm.calculation.carbohydrateStatus;
      })) === null || _vm$constants$nutriti4 === void 0 ? void 0 : _vm$constants$nutriti4.color,
      "width": "240px"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "36px",
      "border-color": "neutral.lightGray"
    }
  }), _c('c-grid', {
    attrs: {
      "template-columns": "auto auto 240px 240px",
      "gap": "16px"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Asupan Serat Sekarang (gram)",
      "placeholder": "Masukkan asupan serat sekarang",
      "is-required": "",
      "right-element": "g",
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fiberIntakeCurrent),
      "invalid-text": _vm.parseErrors('Asupan serat sekarang', _vm.$v.form.fiberIntakeCurrent, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.fiberIntakeCurrent.$touch
    },
    model: {
      value: _vm.form.fiberIntakeCurrent,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fiberIntakeCurrent", $$v);
      },
      expression: "form.fiberIntakeCurrent"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-required": "",
      "label": "Kategori usia kebutuhan serat",
      "placeholder": "Pilih kategori usia kebutuhan serat",
      "options": _vm.constants.fiberNeedsForAgeCategoryOptions,
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fiberNeedsForAgeCategory),
      "invalid-text": _vm.parseErrors('Kategori usia kebutuhan serat', _vm.$v.form.fiberNeedsForAgeCategory)
    },
    on: {
      "blur": _vm.$v.form.fiberNeedsForAgeCategory.$touch
    },
    model: {
      value: _vm.form.fiberNeedsForAgeCategory,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fiberNeedsForAgeCategory", $$v);
      },
      expression: "form.fiberNeedsForAgeCategory"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "% Kecukupan Serat",
      "value": "".concat(_vm.calculation.fiberAdequacy, "%") || '-',
      "color": _vm.calculation.fiberAdequacy ? 'green' : '',
      "width": "240px"
    }
  }), _c('StatusLabel', {
    attrs: {
      "label": "Kebutuhan Asupan Serat",
      "value": _vm.form.fiberNeedsForAgeCategory || '-',
      "color": _vm.form.fiberNeedsForAgeCategory ? 'green' : 'red',
      "width": "240px"
    }
  }), _c('c-box', {
    attrs: {
      "grid-column": "span 2"
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "textarea",
      "label": "Catatan Asupan Serat",
      "placeholder": "Masukkan catatan asupan serat",
      "full-width": "",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": _vm.isInvalidField(_vm.$v.form.fiberIntakeNote) ? '' : 'Maksimal 300 karakter',
      "is-disabled": _vm.isFormDisabled,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.fiberIntakeNote),
      "invalid-text": _vm.parseErrors('Catatan asupan serat', _vm.$v.form.fiberIntakeNote)
    },
    on: {
      "blur": _vm.$v.form.fiberIntakeNote.$touch
    },
    model: {
      value: _vm.form.fiberIntakeNote,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fiberIntakeNote", $$v);
      },
      expression: "form.fiberIntakeNote"
    }
  })], 1), _c('StatusLabel', {
    attrs: {
      "label": "Status Serat",
      "value": _vm.calculation.fiberStatus || '-',
      "color": (_vm$constants$nutriti5 = _vm.constants.nutritionalIntakeColorStatus.find(function (v) {
        return v.value === _vm.calculation.fiberStatus;
      })) === null || _vm$constants$nutriti5 === void 0 ? void 0 : _vm$constants$nutriti5.color,
      "width": "240px"
    }
  })], 1), _c('c-divider', {
    attrs: {
      "margin-top": "20px",
      "margin-bottom": "36px",
      "border-color": "neutral.lightGray"
    }
  })], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "click": _vm.onOpenModalCancel
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStep(_vm.constants.tabs['body-condition'], false);
      }
    }
  }, [_vm._v(" Selanjutnya ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalCancel,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCloseModalCancel
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onConfirmModalCancel
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalNext,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCancelNext
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onStepTo
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('ModalSuccessSaveData', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalSuccessSaveData = false;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalBackToPreview,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin"), _c('br'), _vm._v("ingin membatalkan pengisian formulir? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalBackToPreview = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleBackToPreview
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-warning.svg'),
      "title": "Gagal Menyimpan Data",
      "description": "Terdapat kesalahan data, coba tinjau ulang datamu"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }