var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "gap": "4px",
      "flex-direction": "column",
      "width": _vm.width
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px",
      "color": _vm.getColor.color
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-box', {
    attrs: {
      "padding": "20px",
      "border-radius": "8px",
      "font-size": "18px",
      "font-weight": "500",
      "background-color": _vm.getColor.background,
      "color": _vm.getColor.color
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }