export const calculationTypes = [
  {
    label: 'Dewasa',
    value: 'adult',
  },
  {
    label: 'Anak',
    value: 'child',
  },
]

export const childAgeRanges = [
  {
    label: 'Anak 0 - 60 bulan',
    value: '0 - 60 bulan',
  },
  {
    label: 'Anak > 5 - 18 tahun',
    value: '> 5 - 18 tahun',
  },
]

export const adultFormulas = [
  {
    label: 'Rumus Mifflin-St. Jeor',
    value: 'mifflinStJeorFormula',
  },
  {
    label: 'Rumus PERKENI',
    value: 'perkeniFormula',
  },
  {
    label: 'Rumus CKD',
    value: 'ckdFormula',
  },
  {
    label: 'Input Mandiri',
    value: 'manualFormula',
  },
]

export const childFormulas = [
  {
    label: '0 - 10 tahun',
    value: 'monthFormula',
  },
  {
    label: 'Input Mandiri',
    value: 'manualFormula',
  },
]

export const childFormulas2 = [
  {
    label: '0 - 10 tahun',
    value: 'monthFormula',
  },
  {
    label: 'Laki-laki 11 - 18 tahun',
    value: 'yearMaleFormula',
  },
  {
    label: 'Perempuan 11 - 18 tahun',
    value: 'yearFemaleFormula',
  },
  {
    label: 'Input Mandiri',
    value: 'manualFormula',
  },
]

export const monthFormulas = [
  {
    label: 'Bayi 0 - 5 bulan',
    value: 108,
  },
  {
    label: 'Bayi 6 - 11 bulan',
    value: 98,
  },
  {
    label: 'Anak 1 - 3 tahun',
    value: 102,
  },
  {
    label: 'Anak 4 - 6 tahun',
    value: 90,
  },
  {
    label: 'Anak 7 - 10 tahun',
    value: 70,
  },
]

export const yearMaleFormulas = [
  {
    label: 'Anak Laki-laki 11 - 14 tahun',
    value: 55,
  },
  {
    label: 'Anak Laki-laki 15 - 18 tahun',
    value: 45,
  },
]

export const yearFemaleFormulas = [
  {
    label: 'Anak Perempuan 11 - 14 tahun',
    value: 47,
  },
  {
    label: 'Anak Perempuan 15 - 18 tahun',
    value: 40,
  },
]

export const conclusionOptions = [
  {
    label: 'Menggunakan PP/BB atau TB/BB',
    value: 'nutritionalStatusHeightPerWeight',
  },
  {
    label: 'Menggunakan IMT/U',
    value: 'nutritionalStatusImtPerAge',
  },
]
export const weightOptions = [
  {
    label: 'BB dari Data Dasar',
    value: 'weight',
  },
  {
    label: 'Minimum BBI',
    value: 'minIdealWeight',
  },
  {
    label: 'Maximum BBI',
    value: 'maxIdealWeight',
  },
  {
    label: 'Nilai Tengah BBI',
    value: 'medianIdealWeight',
  },
  {
    label: 'BBI Brocca',
    value: 'broccaIdealWeight',
  },
  {
    label: 'BB Adjusted',
    value: 'adjustedIdealWeight',
  },
]

export const bmiStatus = {
  BERAT_BADAN_KURANG: 'Berat Badan Kurang',
  NORMAL: 'Normal',
  PREOBESITAS: 'Preobesitas',
  OBESITAS_1: 'Obesitas 1',
  OBESITAS_2: 'Obesitas 2',
}

export const physicalActivityOptions = [
  {
    label: 'Keadaan istirahat',
    value: 10,
  },
  {
    label: 'Aktivitas ringan',
    value: 20,
  },
  {
    label: 'Aktivitas sedang',
    value: 30,
  },
  {
    label: 'Aktivitas berat',
    value: 40,
  },
  {
    label: 'Aktivitas sangat berat',
    value: 50,
  },
]

export const proteinRequirementsperkgBodyWeightOptions = [
  {
    label: '0.55',
    value: 0.55,
  },
  {
    label: '0.6',
    value: 0.6,
  },
  {
    label: '0.7',
    value: 0.7,
  },
  {
    label: '0.75',
    value: 0.75,
  },
  {
    label: '0.8',
    value: 0.8,
  },
  {
    label: '0.85',
    value: 0.85,
  },
  {
    label: '0.95',
    value: 0.95,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '1.1',
    value: 1.1,
  },
  {
    label: '1.2',
    value: 1.2,
  },
  {
    label: '1.3',
    value: 1.3,
  },
  {
    label: '1.35',
    value: 1.35,
  },
  {
    label: '1.5',
    value: 1.5,
  },
  {
    label: '1.75',
    value: 1.75,
  },
  {
    label: '1.8',
    value: 1.8,
  },
  {
    label: '1.9',
    value: 1.9,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '2.3',
    value: 2.3,
  },
  {
    label: '2.5',
    value: 2.5,
  },
  {
    label: '2.7',
    value: 2.7,
  },
  {
    label: '3',
    value: 3,
  },
]

export const fatRequirementsOptions = [
  {
    label: '20%',
    value: 20,
  },
  {
    label: '25%',
    value: 25,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '35%',
    value: 35,
  },
  {
    label: '40%',
    value: 40,
  },
]

export const fiberNeedsForAgeCategoryOptions = [
  {
    label: 'Perempuan Dewasa',
    value: 32,
  },
  {
    label: 'Laki-laki Dewasa / Hamil Atau Menyusui',
    value: 37,
  },
  {
    label: 'Perempuan / Laki-laki Lansia',
    value: 25,
  },
  {
    label: 'Perempuan Remaja',
    value: 29,
  },
  {
    label: 'Laki-Laki Remaja',
    value: 34,
  },
  {
    label: 'Anak Dibawah 3 tahun',
    value: 19,
  },
  {
    label: 'Anak 4 - 6 Tahun',
    value: 20,
  },
  {
    label: 'Anak 7 - 9 Tahun',
    value: 23,
  },
]

export const maternalConditionOptions = [
  {
    label: 'Tidak ada',
    value: 0,
  },
  {
    label: 'Hamil Trimester 1',
    value: 180,
  },
  {
    label: 'Hamil Trimester 2 - Trimester 3',
    value: 300,
  },
  {
    label: 'Menyusui Bayi 0-6 Bulan',
    value: 330,
  },
  {
    label: 'Menyusui Bayi 7-12 Bulan',
    value: 400,
  },
]

export const ageFactorOptions = [
  {
    label: 'Kurang dari atau sama dengan 40 tahun',
    value: 0,
  },
  {
    label: '41-59 tahun',
    value: 5,
  },
  {
    label: '60-69 tahun',
    value: 10,
  },
  {
    label: 'Lebih dari atau sama dengan 70 tahun',
    value: 20,
  },
]

export const stressFactorOptions = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '10%',
    value: 10,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '30%',
    value: 30,
  },
]

export const statusFactorOptions = [
  {
    label: '0%',
    value: 0,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '30%',
    value: 30,
  },
]

export const ckdEnergyRequirementOptions = [
  {
    label: '20 kkal/kg BB',
    value: 20,
  },
  {
    label: '25 kkal/kg BB',
    value: 25,
  },
  {
    label: '30 kkal/kg BB',
    value: 30,
  },
  {
    label: '35 kkal/kg BB',
    value: 35,
  },
]

export const nutritionalIntakeColorStatus = [ 
  {
    value: 'Asupanmu cukup',
    color: 'green',
  },
  {
    value: 'Asupanmu kurang',
    color: 'yellow', 
  },
  {
    value: 'Asupanmu berlebih',
    color: 'red',
  },
]

export const tabs = {
  ['nutritional-condition']: 'nutritional-condition',
  ['nutritional-intake']: 'nutritional-intake',
  ['body-condition']: 'body-condition',
  ['target-and-suggestion']: 'target-and-suggestion',
}

export const mealPlanSteps= [
  {
    label: 'Sarapan',
    value: 'breakfast',
  },
  {
    label: 'Snack pagi',
    value: 'morningSnack',
  },
  {
    label: 'Makan siang',
    value: 'lunch',
  },
  {
    label: 'Snack siang/sore',
    value: 'afternoonSnack',
  },
  {
    label: 'Makan malam',
    value: 'dinner',
  },
  {
    label: 'Snack malam',
    value: 'nightSnack',
  },
]

export const mealPlanValueToLabel = {
  breakfast: 'Sarapan',
  morningSnack: 'Snack pagi',
  lunch: 'Makan siang',
  afternoonSnack: 'Snack siang/sore',
  dinner: 'Makan malam',
  nightSnack: 'Snack malam',
}

export const mealPlanStepsWithCustomProperty = ({
  breakfast,
  morningSnack,
  lunch,
  afternoonSnack,
  dinner,
  nightSnack,
}) => ([
  {
    label: 'Sarapan',
    value: 'breakfast',
    ...breakfast,
  },
  {
    label: 'Snack pagi',
    value: 'morningSnack',
    ...morningSnack,
  },
  {
    label: 'Makan siang',
    value: 'lunch',
    ...lunch,
  },
  {
    label: 'Snack siang/sore',
    value: 'afternoonSnack',
    ...afternoonSnack,
  },
  {
    label: 'Makan malam',
    value: 'dinner',
    ...dinner,
  },
  {
    label: 'Snack malam',
    value: 'nightSnack',
    ...nightSnack,
  },
])
export const tabsMealPlan = {
  ['meal-plan']: 'meal-plan',
  ['meal-recommendation']: 'meal-recommendation',
}

export const mealPlanTour = {
  ['tourName']: 'panduan-makan-client',
  ['onboardingName']: 'panduan-makan-client',
}

export const caloriesOptions = [
  {
    label: 'Surplus Kalori',
    value: 'surplus',
  },
  {
    label: 'Defisit Kalori',
    value: 'deficit',
  },
]

export const mealPlansConstant = {
  thresholdConstants: {
    THRESHOLD: 110,
  },
}
