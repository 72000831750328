import { render, staticRenderFns } from "./list-item.vue?vue&type=template&id=4ff2ab19&scoped=true&"
import script from "./list-item.vue?vue&type=script&lang=js&"
export * from "./list-item.vue?vue&type=script&lang=js&"
import style0 from "./list-item.vue?vue&type=style&index=0&id=4ff2ab19&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff2ab19",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CAccordionIcon: require('@chakra-ui/vue').CAccordionIcon, CAccordionHeader: require('@chakra-ui/vue').CAccordionHeader, CAccordionPanel: require('@chakra-ui/vue').CAccordionPanel, CAccordionItem: require('@chakra-ui/vue').CAccordionItem})
