<template>
  <c-flex
    gap="4px"
    flex-direction="column"
    :width="width"
  >
    <c-text
      font-size="16px"
      :color="getColor.color"
    >
      {{ label }}
    </c-text>
    <c-box
      padding="20px"
      border-radius="8px"
      font-size="18px"
      font-weight="500"
      :background-color="getColor.background"
      :color="getColor.color"
    >
      {{ value }}
    </c-box>
  </c-flex>
</template>

<script>
import { CBox, CFlex } from '@chakra-ui/vue'

export default {
  name: 'StatusLabel',
  components: { CFlex, CBox },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    color: {
      type: String,
      default: 'grey',
    },
    width: {
      type: String,
      default: '300px',
    },
  },
  computed: {
    getColor() {
      switch (this.color) {
        case 'green':
          return {
            color: 'primary.500',
            background: 'primary.50',
          }
        case 'yellow':
          return {
            color: 'warning.500',
            background: 'warning.50',
          }

        case 'red':
          return {
            color: 'danger.500',
            background: 'danger.50',
          }
        case 'grey':
        default:
          return {
            color: 'neutral.gray',
            background: 'neutral.superLightGray',
          }
      }
    },
  },
}
</script>

<style  scoped>

</style>