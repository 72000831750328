<template>
  <c-box>
    <c-box
      position="sticky"
      top="calc(84px)"
      background-color="white"
      z-index="10"
      box-shadow="0px 16px 0px white, 10px 16px 0px white, -10px 16px 0px white, 10px -16px 0px white, -10px -16px 0px white"
    >
      <c-flex
        width="100%"
        align-items="center"
        justify-content="space-between"
        margin-bottom="16px"
      >
        <c-button
          variant="ghost"
          width="30px"
          min-width="30px"
          height="30px"
          margin="0"
          padding="0"
          margin-right="16px"
          @click="onClickBackToPreview"
        >
          <c-box
            :width="['12px', '15px']"
            :height="['12px', '15px']"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-down.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
              style="transform: rotate(90deg);"
            />
          </c-box>
        </c-button>
        <c-text
          color="neutral.superDarkGray"
          font-size="22px"
          font-weight="600"
          margin-right="auto"
        >
          Asupan Gizi
        </c-text>
        <c-tooltip
          :label="iconCloud.text"
          placement="bottom"
        >
          <c-box
            :width="['35px']"
            :height="['35px']"
            margin-right="16px"
            :class="iconCloud.state === 'loading' ? 'animate-spin' : ''"
          >
            <inline-svg
              :src="iconCloud.icon"
              height="100%"
              width="100%"
              fill="#008C81"
            />
          </c-box>
        </c-tooltip>
        <BaseButton
          :left-svg-icon="require('@/assets/icons/icon-save.svg')"
          left-svg-icon-color="white"
          :disabled="!isValidAllFormProfilGizi || saveState === null || isLoadingSave"
          @click="onSave"
        >
          Simpan
        </BaseButton>
      </c-flex>

      <!-- widget info -->
      <c-flex
        v-if="isFormDisabled"
        margin-top="16px"
        background-color="danger.50"
        padding="6px 10px"
        border-radius="8px"
        align-items="center"
        gap="10px"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-circle-warn.svg')"
          height="20px"
          width="20px"
          fill="#D32737"
        />
        <c-text
          color="danger.400"
          font-size="14px"
        >
          Selesaikan formulir Kondisi Giziku terlebih dahulu untuk mengaktifkan formulir ini
        </c-text>
      </c-flex>

      <NutritionalProfileSteps
        :draft="draft"
        :nutritional-intake="!$v?.$invalid ? 1 : -1"
        :is-loading="isLoadingSave || saveState === null"
        @change:step="(step) => handleChangeStep(step, true)"
      />
    </c-box>

    <c-box padding-top="16px">
      <c-box>
        <c-grid 
          template-columns="auto auto 240px 240px"
          gap="16px"
        >
          <FormInput
            v-model="form.energyIntakeCurrent"
            type="text"
            label="Asupan Energi Sekarang (kkal)"
            placeholder="Masukkan asupan energi sekarang"
            is-required
            right-element="kkal"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.energyIntakeCurrent)"
            :invalid-text="parseErrors('Asupan energi sekarang', $v.form.energyIntakeCurrent, customMessageErrors)"
            @blur="$v.form.energyIntakeCurrent.$touch"
          />
          
          <FormInput
            v-model="form.energyIntakeNote"
            type="textarea"
            label="Catatan Asupan Energi"
            placeholder="Masukkan catatan energi sekarang"
            full-width
            is-required
            is-helper
            helper-text-color="#888"
            helper-text-type="info"
            :helper-text="isInvalidField($v.form.energyIntakeNote) ? '': 'Maksimal 300 karakter'"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.energyIntakeNote)"
            :invalid-text="parseErrors('Catatan asupan energi', $v.form.energyIntakeNote)"
            @blur="$v.form.energyIntakeNote.$touch"
          />
          
          <StatusLabel
            label="% Kecukupan Energi"
            :value="`${calculation.energySufficiency}%` || '-'"
            :color="calculation.energySufficiency ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="Status Energi"
            :value="calculation.energyStatus || '-'"
            :color="constants.nutritionalIntakeColorStatus.find((v) => v.value === calculation.energyStatus)?.color"
            width="240px"
          />
        </c-grid>

        <c-divider
          margin-top="20px"
          margin-bottom="36px"
          border-color="neutral.lightGray"
        />

        <c-grid
          template-columns="auto auto 240px 240px"
          gap="16px"
        >
          <FormInput
            v-model="form.proteinIntakeCurrent"
            type="text"
            label="Asupan Protein Sekarang (gram)"
            placeholder="Masukkan asupan protein sekarang"
            is-required
            right-element="g"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.proteinIntakeCurrent)"
            :invalid-text="parseErrors('Asupan protein sekarang', $v.form.proteinIntakeCurrent, customMessageErrors)"
            @blur="$v.form.proteinIntakeCurrent.$touch"
          />

          <FormSelect
            v-model="form.proteinRequirementsperkgBodyWeight"
            is-required
            label="Kebutuhan Protein per kg Berat Badan"
            placeholder="Masukkan kebutuhan protein per kg bb"
            :options="constants.proteinRequirementsperkgBodyWeightOptions"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.proteinRequirementsperkgBodyWeight)"
            :invalid-text="parseErrors('Kebutuhan protein per kg bb', $v.form.proteinRequirementsperkgBodyWeight)"
            @blur="$v.form.proteinRequirementsperkgBodyWeight.$touch"
          />

          <StatusLabel
            label="Kebutuhan Asupan Protein"
            :value="calculation.proteinRequirement || '-'"
            :color="calculation.proteinRequirement ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="Konstanta Kebutuhan Protein"
            :value="calculation.proteinRequirementConstant || '-'"
            :color="calculation.proteinRequirementConstant ? 'green' : ''"
            width="240px"
          />

          <c-box
            grid-column="span 2"
          >
            <FormInput
              v-model="form.proteinIntakeNote"
              type="textarea"
              label="Catatan Asupan Protein"
              placeholder="Masukkan catatan Asupan Protein"
              full-width
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="isInvalidField($v.form.proteinIntakeNote) ? '': 'Maksimal 300 karakter'"
              :is-disabled="isFormDisabled"
              :is-invalid="isInvalidField($v.form.proteinIntakeNote)"
              :invalid-text="parseErrors('Catatan asupan protein', $v.form.proteinIntakeNote)"
              @blur="$v.form.proteinIntakeNote.$touch"
            />
          </c-box>

          <StatusLabel
            label="% Kecukupan Protein"
            :value="calculation.proteinAdequacy ? `${calculation.proteinAdequacy}%` : '-'"
            :color="calculation.proteinAdequacy ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="Status Protein"
            :value="calculation.proteinStatus"
            :color="constants.nutritionalIntakeColorStatus.find((v) => v.value === calculation.proteinStatus)?.color"
            width="240px"
          />
        </c-grid>

        <c-divider
          margin-top="20px"
          margin-bottom="36px"
          border-color="neutral.lightGray"
        />

        <c-grid 
          template-columns="auto auto 240px 240px"
          gap="16px"
        >
          <FormInput
            v-model="form.fatIntakeCurrent"
            type="text"
            label="Asupan Lemak Sekarang (gram)"
            placeholder="Masukkan asupan lemak sekarang"
            is-required
            right-element="g"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.fatIntakeCurrent)"
            :invalid-text="parseErrors('Asupan lemak sekarang', $v.form.fatIntakeCurrent, customMessageErrors)"
            @blur="$v.form.fatIntakeCurrent.$touch"
          />

          <FormSelect
            v-model="form.fatRequirements"
            is-required
            label="% Kebutuhan Lemak"
            placeholder="Masukkan % kebutuhan lemak "
            :options="constants.fatRequirementsOptions"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.fatRequirements)"
            :invalid-text="parseErrors('% kebutuhan lemak', $v.form.fatRequirements)"
            @blur="$v.form.fatRequirements.$touch"
          />

          <StatusLabel
            label="% Kecukupan Lemak"
            :value="`${calculation.fatAdequacy}%` || '-'"
            :color="calculation.fatAdequacy ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="Kebutuhan Asupan Lemak"
            :value="calculation.fatRequirement || '-'"
            :color="calculation.fatRequirement ? 'green' : 'red'"
            width="240px"
          />

          <c-box
            grid-column="span 2"
          >
            <FormInput
              v-model="form.fatIntakenote"
              type="textarea"
              label="Catatan Asupan Lemak"
              placeholder="Masukkan catatan asupan lemak"
              full-width
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="isInvalidField($v.form.fatIntakenote) ? '': 'Maksimal 300 karakter'"
              :is-disabled="isFormDisabled"
              :is-invalid="isInvalidField($v.form.fatIntakenote)"
              :invalid-text="parseErrors('Catatan asupan lemak', $v.form.fatIntakenote)"
              @blur="$v.form.fatIntakenote.$touch"
            />
          </c-box>

          <StatusLabel
            label="Status Lemak"
            :value="calculation.fatStatus || '-'"
            :color="constants.nutritionalIntakeColorStatus.find((v) => v.value === calculation.fatStatus)?.color"
            width="240px"
          />
        </c-grid>

        <c-divider
          margin-top="20px"
          margin-bottom="36px"
          border-color="neutral.lightGray"
        />

        <!-- Carbo -->
        <c-grid
          template-columns="auto auto 240px 240px"
          gap="16px"
        >
          <c-box
            grid-column="span 2"
          >
            <FormInput
              v-model="form.carboIntakeCurrent"
              type="text"
              label="Asupan Karbohidrat Sekarang (gram)"
              placeholder="Masukkan asupan karbohidrat sekarang"
              is-required
              right-element="g"
              :is-disabled="isFormDisabled"
              :is-invalid="isInvalidField($v.form.carboIntakeCurrent)"
              :invalid-text="parseErrors('Asupan karbohidrat sekarang', $v.form.carboIntakeCurrent, customMessageErrors)"
              @blur="$v.form.carboIntakeCurrent.$touch"
            />
          </c-box>

          <StatusLabel
            label="Kebutuhan Asupan Karbohidrat"
            :value="calculation.carbohydrateRequirement || '-'"
            :color="calculation.carbohydrateRequirement ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="% Kecukupan Karbohidrat"
            :value="calculation.carbohydrateAdequacy ? `${calculation.carbohydrateAdequacy}%` : '-'"
            :color="calculation.carbohydrateAdequacy ? 'green' : ''"
            width="240px"
          />

          <c-box
            grid-column="span 2"
          >
            <FormInput
              v-model="form.carboIntakeNote"
              type="textarea"
              label="Catatan Asupan Karbohidrat"
              placeholder="Masukkan catatan asupan karbohidrat"
              full-width
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="isInvalidField($v.form.carboIntakeNote) ? '': 'Maksimal 300 karakter'"
              :is-disabled="isFormDisabled"
              :is-invalid="isInvalidField($v.form.carboIntakeNote)"
              :invalid-text="parseErrors('Catatan asupan karbohidrat', $v.form.carboIntakeNote)"
              @blur="$v.form.carboIntakeNote.$touch"
            />
          </c-box>

          <StatusLabel
            label="Status Karbohidrat"
            :value="calculation.carbohydrateStatus || '-'"
            :color="constants.nutritionalIntakeColorStatus.find((v) => v.value === calculation.carbohydrateStatus)?.color"
            width="240px"
          />
        </c-grid>

        <c-divider
          margin-top="20px"
          margin-bottom="36px"
          border-color="neutral.lightGray"
        />

        <!-- fiber -->
        <c-grid
          template-columns="auto auto 240px 240px"
          gap="16px"
        >
          <FormInput
            v-model="form.fiberIntakeCurrent"
            type="text"
            label="Asupan Serat Sekarang (gram)"
            placeholder="Masukkan asupan serat sekarang"
            is-required
            right-element="g"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.fiberIntakeCurrent)"
            :invalid-text="parseErrors('Asupan serat sekarang', $v.form.fiberIntakeCurrent, customMessageErrors)"
            @blur="$v.form.fiberIntakeCurrent.$touch"
          />

          <FormSelect
            v-model="form.fiberNeedsForAgeCategory"
            is-required
            label="Kategori usia kebutuhan serat"
            placeholder="Pilih kategori usia kebutuhan serat"
            :options="constants.fiberNeedsForAgeCategoryOptions"
            :is-disabled="isFormDisabled"
            :is-invalid="isInvalidField($v.form.fiberNeedsForAgeCategory)"
            :invalid-text="parseErrors('Kategori usia kebutuhan serat', $v.form.fiberNeedsForAgeCategory)"
            @blur="$v.form.fiberNeedsForAgeCategory.$touch"
          />

          <StatusLabel
            label="% Kecukupan Serat"
            :value="`${calculation.fiberAdequacy}%` || '-'"
            :color="calculation.fiberAdequacy ? 'green' : ''"
            width="240px"
          />

          <StatusLabel
            label="Kebutuhan Asupan Serat"
            :value="form.fiberNeedsForAgeCategory || '-'"
            :color="form.fiberNeedsForAgeCategory ? 'green': 'red'"
            width="240px"
          />

          <c-box
            grid-column="span 2"
          >
            <FormInput
              v-model="form.fiberIntakeNote"
              type="textarea"
              label="Catatan Asupan Serat"
              placeholder="Masukkan catatan asupan serat"
              full-width
              is-required
              is-helper
              helper-text-color="#888"
              helper-text-type="info"
              :helper-text="isInvalidField($v.form.fiberIntakeNote) ? '': 'Maksimal 300 karakter'"
              :is-disabled="isFormDisabled"
              :is-invalid="isInvalidField($v.form.fiberIntakeNote)"
              :invalid-text="parseErrors('Catatan asupan serat', $v.form.fiberIntakeNote)"
              @blur="$v.form.fiberIntakeNote.$touch"
            />
          </c-box>

          <StatusLabel
            label="Status Serat"
            :value="calculation.fiberStatus || '-'"
            :color="constants.nutritionalIntakeColorStatus.find((v) => v.value === calculation.fiberStatus)?.color"
            width="240px"
          />
        </c-grid>
        
        <c-divider
          margin-top="20px"
          margin-bottom="36px"
          border-color="neutral.lightGray"
        />
      </c-box>

      <c-grid
        w="100%"
        :template-columns="['repeat(2, 1fr)']"
        :gap="['16px']"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="onOpenModalCancel"
        >
          Sebelumnya
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave || saveState === null"
          @click="handleChangeStep(constants.tabs['body-condition'], false)"
        >
          Selanjutnya
        </c-button>
      </c-grid>

      <BaseModal
        :is-open="!!isOpenModalCancel"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 16px 20px 16px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
              margin-bottom="8px"
            >
              Data kamu belum lengkap
            </BaseText>
            <BaseText
              size-mobile="12px"
              size-desktop="14px"
              color="neutral.darkGray"
            >
              Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCloseModalCancel"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onConfirmModalCancel"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>

      <BaseModal
        :is-open="!!isOpenModalNext"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 16px 20px 16px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
              margin-bottom="8px"
            >
              Data kamu belum lengkap
            </BaseText>
            <BaseText
              size-mobile="12px"
              size-desktop="14px"
              color="neutral.darkGray"
            >
              Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCancelNext"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onStepTo"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>

    <!-- MODAL -->
    <ModalSuccessSaveData
      :is-open="isOpenModalSuccessSaveData"
      @close="isOpenModalSuccessSaveData = false"
    />

    <!-- Modal back to preview -->
    <BaseModal
      :is-open="isOpenModalBackToPreview"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            Apakah anda yakin<br>ingin membatalkan pengisian formulir?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="isOpenModalBackToPreview = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackToPreview"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-warning.svg')"
      title="Gagal Menyimpan Data"
      description="Terdapat kesalahan data, coba tinjau ulang datamu"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import _ from 'lodash'
import {
  proteinRequirementsperkgBodyWeightOptions,
  fatRequirementsOptions,
  fiberNeedsForAgeCategoryOptions, 
  tabs,
  nutritionalIntakeColorStatus,
} from '@/constants/meal-plans'
import {
  energySufficiencyFormula,
  proteinIntakeFormula,
  fatIntakeFormula,
  carbohydrateIntakeFormula,
  fiberIntakeFormula,
} from '@/utils/meal-plans'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import NutritionalProfileSteps from '@/components/meal-plan/_widgets/steps.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import FormInput from '@/views/client/kuisioner/forms/form-input-2.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2.vue'
import { CBox, CButton, CFlex, CGrid, CImage, CText } from '@chakra-ui/vue'
import { maxLength, numeric, required } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import ModalSuccessSaveData
  from '@/components/meal-plan/_widgets/modal-success-save-data.vue'
import EventBus from '@/utils/event-bus'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { mapGetters, mapMutations } from 'vuex'
import StatusLabel from '@/components/meal-plan/_widgets/status-label.vue'

export default {
  name: 'FormIntake',
  components: {
    ModalSuccessSaveData,
    CBox,
    CFlex,
    CImage,
    CButton,
    CText,
    CGrid,
    FormSelect,
    FormInput,
    BaseModal,
    BaseText,
    BaseButton,
    NutritionalProfileSteps,
    ModalSimpleInfo,
    StatusLabel,
  },
  props: {
    dataPreview: {
      type: Object,
      default: () => ({}),
    },
    draft: {
      type: [Object, null],
      default: null,
    },
    isDraftExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      isOpenModalBackToPreview: false,
      isOpenModalNext: false,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      formDuplicate: {},
      form: {
        energyIntakeCurrent: null,
        energyIntakeNote: null,
        proteinIntakeCurrent: null,
        proteinRequirementsperkgBodyWeight: null,
        proteinIntakeNote: null,
        fatIntakeCurrent: null,
        fatRequirements: null,
        fatIntakenote: null,
        carboIntakeCurrent: null,
        carboIntakeNote: null,
        fiberIntakeCurrent: null,
        fiberNeedsForAgeCategory: null,
        fiberIntakeNote: null,
      },
      isLoadingSave: false,
      isLoadingData: true,
      isOpenModalCancel: false,
      stepTo: {
        tab: '',
        savePosition: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      saveState: 'general/saveState',
    }),
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
            state: 'success',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
            state: 'failed',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
            state: 'loading',
          }
      }
    },
    isFormDisabled() {
      return !this.draft?.nutritionalConditions || this.draft?.nutritionalConditions?.validation === -1
    },
    isSaveDisabled() {
      if (!this.hasChanges) {
        return true
      }
      return this.isLoadingSave
    },
    hasChanges() {
      return !_.isEqual(this.form, this.formDuplicate)
    },
    constants() {
      return {
        proteinRequirementsperkgBodyWeightOptions,
        fatRequirementsOptions,
        fiberNeedsForAgeCategoryOptions,
        tabs,
        nutritionalIntakeColorStatus,
      }
    },
    calculation() {
      const totalEnergyNeeded = parseFloat(this.dataPreview?.optConditions?.nutritionalConditions?.totalEnergyNeeded)
      const weight = parseFloat(
        this.draft?.nutritionalConditions?.mifflinStJeorFormula?.weightValue
          || this.draft?.nutritionalConditions?.perkeniFormula?.weightValue
          || this.draft?.nutritionalConditions?.ckdFormula?.weightValue
          || this.draft?.nutritionalConditions?.manualFormula?.weightValue
          || this.draft?.nutritionalConditions?.weight,
      )

      const proteinRequirementConstant = proteinIntakeFormula.proteinRequirementConstant(weight, parseFloat(this.form.proteinRequirementsperkgBodyWeight), totalEnergyNeeded)
      const fatRequirement = fatIntakeFormula.fatRequirement(totalEnergyNeeded, parseFloat(this.form.fatRequirements))
      return {
        energySufficiency: energySufficiencyFormula.energySufficiency(parseFloat(totalEnergyNeeded), parseFloat(this.form.energyIntakeCurrent)),
        energyStatus: energySufficiencyFormula.energyStatus(parseFloat(totalEnergyNeeded), parseFloat(this.form.energyIntakeCurrent)),
        proteinRequirement: proteinIntakeFormula.proteinRequirement(parseFloat(weight), parseFloat(this.form.proteinRequirementsperkgBodyWeight)),
        proteinRequirementConstant,
        proteinAdequacy: proteinIntakeFormula.proteinAdequacy(parseFloat(weight), parseFloat(this.form.proteinRequirementsperkgBodyWeight), parseFloat(this.form.proteinIntakeCurrent)),
        proteinStatus: proteinIntakeFormula.proteinStatus(parseFloat(weight), parseFloat(this.form.proteinRequirementsperkgBodyWeight), parseFloat(this.form.proteinIntakeCurrent)),
        fatRequirement,
        fatAdequacy: fatIntakeFormula.fatAdequacy(parseFloat(totalEnergyNeeded), parseFloat(this.form.fatRequirements), parseFloat(this.form.fatIntakeCurrent)),
        fatStatus: fatIntakeFormula.fatStatus(parseFloat(totalEnergyNeeded), parseFloat(this.form.fatRequirements), parseFloat(this.form.fatIntakeCurrent)),
        carbohydrateRequirement: carbohydrateIntakeFormula.carbohydrateRequirement(parseFloat(totalEnergyNeeded), parseFloat(proteinRequirementConstant), parseFloat(this.form.fatRequirements)),
        carbohydrateAdequacy: carbohydrateIntakeFormula.carbohydrateAdequacy(parseFloat(totalEnergyNeeded), parseFloat(proteinRequirementConstant), parseFloat(this.form.fatRequirements), parseFloat(this.form.carboIntakeCurrent)),
        carbohydrateStatus: carbohydrateIntakeFormula.carbohydrateStatus(parseFloat(totalEnergyNeeded), parseFloat(proteinRequirementConstant), parseFloat(this.form.fatRequirements), parseFloat(this.form.carboIntakeCurrent)),
        fiberAdequacy: fiberIntakeFormula.fiberAdequacy(parseFloat(this.form.fiberNeedsForAgeCategory), parseFloat(this.form.fiberIntakeCurrent)),
        fiberStatus: fiberIntakeFormula.fiberStatus(parseFloat(this.form.fiberNeedsForAgeCategory), parseFloat(this.form.fiberIntakeCurrent)),
      }
    },
    isValidAllFormProfilGizi() {
      const isAllValid = isAllFormValid({
        nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
        nutritionalIntake: !this.$v?.$invalid,
        bodyConditions: this.draft?.bodyConditions?.validation === 1,
        targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
      })
      return isAllValid
    },
    customMessageErrors() {
      return {
        numeric: '{{fieldName}} hanya boleh diisi dengan bilangan tanpa koma atau titik',
      }
    },
  },
  watch: {
    hasChanges: {
      handler: _.debounce(function(val) {
        if (val) {
          this.formDuplicate = _.cloneDeep(this.form)
          this.onSaveDraft()
        } else if (this.saveState === null) {
          this.setSaveState(true)

        }
      }, 3000),
    },
    form: {
      handler() {
        this.setSaveState(null)
        const data = {
          form: 'nutritional-intake',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.draft?.nutritionalIntake && !_.isEmpty(this.draft?.nutritionalIntake)) {
      this.form = this.draft?.nutritionalIntake
      this.$v.$touch()
    }

    this.formDuplicate = _.cloneDeep(this.form)
    setTimeout(() => {
      this.isLoadingData = false
    }, 1500)
  },
  methods: {
    ...mapMutations({
      setSaveState: 'general/setSaveState',
    }),
    isAllFormValid,
    isInvalidField,
    parseErrors,
    onClickBackToPreview() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalBackToPreview = true
        return
      }
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    handleBackToPreview() {
      this.isOpenModalBackToPreview = false
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    onOpenModalCancel() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.isOpenModalCancel = true
        return
      }
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['nutritional-condition'],
        },
      })
    },
    onCloseModalCancel() {
      this.isOpenModalCancel = false
    },
    onConfirmModalCancel() {
      this.onCloseModalCancel()
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['nutritional-condition'],
        },
      })
    },
    handleChangeStep(tab, savePosition) {
      this.$v.$touch()
      this.stepTo.tab = tab
      this.stepTo.savePosition = savePosition
      if (this.$v.$invalid) {
        this.isOpenModalNext = true
        return
      }
      this.onStepTo()
    },
    onCancelNext() {
      this.isOpenModalNext = false
    },
    async onStepTo() {
      try {
        await this.onSaveDraft()
        this.$router.replace({
          name: 'nutri.meal-plan.profile.edit',
          params: {
            ...this.$route.params,
            savePosition: this.stepTo.savePosition,
          },
          query: {
            tab: this.stepTo.tab,
          },
        })
      } catch (e) {
        //
      }
    },
    async onSave() {
      try {
        await this.onSaveDraft(true)
      } catch (err) {
        this.isOpenModalFailedSaveData = true
      }
    },
    async onSaveDraft(confirm = null) {
      try {
        this.isLoadingSave = true
        this.setSaveState(null)

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: Number(this.$route.params.month),
          nutritionalIntake: {
            ...this.form,
            ...this.calculation,
            validation: this.$v.$invalid ? -1 : 1,
          },
        }

        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })

        this.$v.$touch()

        if (!isAllFormValid({
          nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
          nutritionalIntake: !this.$v?.$invalid,
          bodyConditions: this.draft?.bodyConditions?.validation === 1,
          targetAndSuggestions: this.draft?.targetAndSuggestions?.validation === 1,
        })) {
          EventBus.$emit('TRIGGER_SAVE_MEAL_PLAN', Date.now())
        }
        // this.formDuplicate = _.cloneDeep(this.form)
        this.setSaveState(true)
        if (confirm) {
          this.isOpenModalSuccessSaveData = true
        }
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
  },
  validations() {
    return {
      form: {
        energyIntakeCurrent: {
          numeric,
          required,
        },
        energyIntakeNote: {
          required,
          maxLength: maxLength(300),
        },
        proteinIntakeCurrent: {
          numeric,
          required,
        },
        proteinRequirementsperkgBodyWeight: {
          required,
        },
        proteinIntakeNote: {
          required,
          maxLength: maxLength(300),
        },
        fatIntakeCurrent: {
          numeric,
          required,
        },
        fatRequirements: {
          numeric,
          required,
        },
        fatIntakenote: {
          required,
          maxLength: maxLength(300),
        },
        carboIntakeCurrent: {
          numeric,
          required,
        },
        carboIntakeNote: {
          required,
          maxLength: maxLength(300),
        },
        fiberIntakeCurrent: {
          numeric,
          required,
        },
        fiberNeedsForAgeCategory: {
          numeric,
          required,
        },
        fiberIntakeNote: {
          required,
          maxLength: maxLength(300),
        },
      },
    }
  },
}
</script>
